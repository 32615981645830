import { useEffect, useState } from "react";
import { Drawer, Box, Button, Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MenuSection from "./MenuSection";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "react-router";
import { CSSObject, Theme, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {
  Storage,
  Dns,
  Apps,
  Room,
  Backup,
  Key,
  ExitToApp,
  Campaign,
  Archive,
  Source,
  Edit,
  Handyman,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { RolesEnum } from "@/types/others.types";
import AuthService from "@/services/AuthService";
import IncidentRouteLabelWithBadge from "./IncidentRouteLabelWithBadge";
const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface VerticalMenuProps {
  defaultDrawerWidth: number;
  actualDrawerWidth: number;
  setActualDrawerWidth: (value: number) => void;
}

const VerticalMenu = (props: VerticalMenuProps) => {
  const { defaultDrawerWidth, actualDrawerWidth, setActualDrawerWidth } = props;
  const [open, setOpen] = useState<boolean>(
    defaultDrawerWidth === actualDrawerWidth,
  );
  const theme = useTheme();
  const defaultSection = AuthService.hasRole(RolesEnum.Admin)
    ? "Webservices ovh"
    : "Infra";
  const [expandedSection, setExpandedSection] = useState(defaultSection);

  useEffect(
    () => setOpen(defaultDrawerWidth === actualDrawerWidth),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actualDrawerWidth],
  );
  const navigate: NavigateFunction = useNavigate();

  return (
    <Box
      sx={{ border: "2px red", boxShadow: "0 4px 8px rgb(102 109 146 / 8%)" }}
    >
      <CssBaseline />
      <Drawer
        sx={{
          width: `${actualDrawerWidth}px`,
          flexShrink: 0,
          whiteSpace: "nowrap",
          "& .MuiDrawer-paper": {
            width: `${actualDrawerWidth}px`,
            boxSizing: "border-box",
            backgroundColor: "cobalt.white",
          },
          ...(open && {
            ...openedMixin(theme, actualDrawerWidth),
            "& .MuiDrawer-paper": openedMixin(theme, actualDrawerWidth),
          }),
          ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
          }),
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          component="img"
          sx={{
            textAlign: "center",
            py: "1rem",
            px: "3rem",
            cursor: "pointer",
          }}
          alt="Logo da-exploitation"
          src="/images/logo-da-exploitation.svg"
          onClick={() => navigate("/")}
        />
        <Divider
          variant="fullWidth"
          sx={{ fontSize: "0.1em", color: "cobalt.ultramarine" }}
        />
        {AuthService.hasRole(RolesEnum.Admin) && (
          <MenuSection
            title="Webservices OVH"
            setExpandedSection={setExpandedSection}
            expandedSection={expandedSection}
            titlesAndRoutesButtons={[
              { label: "IPs", route: "/ips", icon: <Room /> },
              {
                label: "Serveurs",
                route: "/serveurs",
                icon: <Storage />,
              },
              { label: "NAS", route: "/nas", icon: <Backup /> },
              { label: "DNS", route: "/dns", icon: <Dns /> },
              {
                label: "Applications",
                route: "/applications",
                icon: <Apps />,
              },
              { label: "Credentials", route: "/credentials", icon: <Key /> },
            ]}
            open={open}
            widthItem={defaultDrawerWidth}
          ></MenuSection>
        )}
        <Divider
          variant="fullWidth"
          sx={{ fontSize: "0.1em", color: "cobalt.ultramarine" }}
        />
        <MenuSection
          title="Infra"
          setExpandedSection={setExpandedSection}
          expandedSection={expandedSection}
          titlesAndRoutesButtons={[
            {
              label: <IncidentRouteLabelWithBadge />,
              route: "/suivi-incidents",
              icon: <Campaign />,
            },
            {
              label: "Incidents archivés",
              route: "/incidents-archives",
              icon: <Archive />,
            },
            { label: "Nouveau ticket", route: "/ticket", icon: <Edit /> },
            { label: "Outils", route: "/outils", icon: <Handyman /> },
          ]}
          open={open}
          widthItem={defaultDrawerWidth}
        ></MenuSection>
        <Divider
          variant="fullWidth"
          sx={{ fontSize: "0.1em", color: "cobalt.ultramarine" }}
        />
        <MenuSection
          title="Projets"
          setExpandedSection={setExpandedSection}
          expandedSection={expandedSection}
          titlesAndRoutesButtons={[
            {
              label: "Liste des projets",
              route: "/projets",
              icon: <Source />,
            },
          ]}
          open={open}
          widthItem={defaultDrawerWidth}
        />

        <Stack
          spacing={2}
          sx={{
            justifyContent: "center",
            width: defaultDrawerWidth,
            mt: "1vh",
          }}
        >
          {/*  */}
        </Stack>
        <Box
          sx={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <IconButton
            component="span"
            onClick={() =>
              open
                ? setActualDrawerWidth(55)
                : setActualDrawerWidth(defaultDrawerWidth)
            }
          />
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Stack spacing={4}>
            {open ? (
              <>
                {/* <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "cobalt.userBlue",
                    fontSize: "0.75em",
                    opacity: open ? 1 : 0,
                  }}
                  onClick={() => {
                    navigate("/mes-informations");
                  }}
                >
                  Mes informations
                </Button> */}
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "cobalt.userBlue",
                  }}
                  onClick={() => {
                    AuthService.doLogout();
                  }}
                >
                  Me déconnecter
                </Button>
              </>
            ) : (
              <>
                {/* <IconButton
                  component="span"
                  sx={{
                    color: "cobalt.userBlue",
                  }}
                  onClick={() => {
                    navigate("/mes-informations");
                  }}
                >
                  <AccountBox />
                </IconButton> */}
                <IconButton
                  component="span"
                  sx={{
                    color: "cobalt.userBlue",
                  }}
                  onClick={() => AuthService.doLogout()}
                >
                  <ExitToApp />
                </IconButton>
              </>
            )}
            <IconButton
              component="span"
              onClick={() =>
                open
                  ? setActualDrawerWidth(55)
                  : setActualDrawerWidth(defaultDrawerWidth)
              }
            >
              {open ? (
                <KeyboardDoubleArrowLeft />
              ) : (
                <KeyboardDoubleArrowRight />
              )}
            </IconButton>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
};

export default VerticalMenu;
