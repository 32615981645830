import AuthService from "@/services/AuthService";
import { useDeleteTool } from "@/services/ToolService";
import { RolesEnum } from "@/types/others.types";
import { Tool } from "@/types/tools.types";
import { CobaltIcon, Dialog } from "@docaposte-agility/da-design-system";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import ButtonWithConfirm from "../ButtonWithConfirm";

interface ModalToolProps {
  tool: Tool;
  open: boolean;
  onClose(): void;
  editTool(tool: Tool): void;
}

const ModalTool = ({ tool, open, onClose, editTool }: ModalToolProps) => {
  const canEdit = AuthService.hasRole(RolesEnum.Admin);
  const queryClient = useQueryClient();
  const { mutate: deleteTool } = useDeleteTool({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries("tools");
    },
  });

  const handleDeleteTool = () => {
    deleteTool(tool.id!);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item display="flex" alignItems="center">
            <img src={tool.logo} alt={`logo de ${tool.name}`} height="40px" />
            <Typography variant="h2" ml={2}>
              {tool.name}
            </Typography>
          </Grid>
          <Grid item flexGrow={1}>
            <Stack direction="row" justifyContent="end" spacing={1}>
              {canEdit && (
                <>
                  <ButtonWithConfirm
                    icon
                    confirmText="Voulez vous vraiment supprimer cet outil ?"
                    onConfirm={handleDeleteTool}
                  >
                    <CobaltIcon
                      name="trash-2"
                      fontSize={20}
                      color="cobalt.ultramarine"
                    />
                  </ButtonWithConfirm>
                  <IconButton onClick={() => editTool(tool)}>
                    <CobaltIcon
                      name="edit"
                      fontSize={20}
                      color="cobalt.ultramarine"
                    />
                  </IconButton>
                </>
              )}

              <IconButton onClick={() => window.open(tool.link, "_blank")}>
                <CobaltIcon
                  name="external-link"
                  fontSize={20}
                  color="cobalt.ultramarine"
                />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h4" mb={1}>
          Description
        </Typography>
        <Typography variant="body1">{tool.description}</Typography>
        <Typography variant="body1" pt={1}>
          <Link to={tool.manual_link} target="_blank">
            Accès au manuel
          </Link>
        </Typography>
        <Typography variant="h4" mb={1} mt={2}>
          Contact
        </Typography>
        <Link to={tool.contact_link} target="_blank">
          <Typography variant="body1">{tool.contact_label}</Typography>
        </Link>
      </DialogContent>
    </Dialog>
  );
};

export default ModalTool;
