import { DataGrid, GridValidRowModel, DataGridProps } from "@mui/x-data-grid";
import { Typography, Box } from "@mui/material";

interface GridDataProps extends DataGridProps {
  rows: Array<GridValidRowModel>;
  noBorder?: boolean;
  fixedSize?: number;
}

export const GridData = (props: GridDataProps) => {
  const { rows, columns, noBorder, fixedSize, ...rest } = props;

  let i = 1;

  return (
    <DataGrid
      rows={rows || []}
      getRowId={() => i++}
      columns={columns}
      disableColumnMenu
      disableColumnSelector
      hideFooterSelectedRowCount
      pageSizeOptions={[5, 10, 25, 50, 100]}
      componentsProps={{
        pagination: {
          labelRowsPerPage: "Éléments par page",
        },
      }}
      initialState={{
        pagination: fixedSize
          ? { paginationModel: { pageSize: fixedSize } }
          : undefined,
      }}
      slots={{
        noRowsOverlay: NoRowsComponent,
      }}
      sx={{
        height: rows.length === 0 ? "15rem" : "none",
        fontSize: "1.5vh",
        backgroundColor: "cobalt.white",
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "cobalt.bleuDigital05",
        },
        "& .MuiDataGrid-columnHeaders": {
          color: "cobalt.ultramarine",
        },
        "& .MuiDataGrid-cell": {
          textAlign: "center",
        },
        boxShadow: noBorder ? "none" : "0 4px 8px rgb(102 109 146 / 8%)",
        borderRadius: "10px",
      }}
      {...rest}
    />
  );
};

const NoRowsComponent = () => (
  <Box height="100%" display="flex" alignItems="center" justifyContent="center">
    <Typography textAlign="center">Aucune donnée</Typography>
  </Box>
);
