import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Grid,
  IconButton,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import Textfield from "@/components/Textfield";
import { FormikProps, getIn } from "formik";
import { NewComerTicket, TicketFormData } from "@/types/tickets.types";

interface BitwardenRightFormProps {
  formik: FormikProps<TicketFormData<NewComerTicket>>;
  attributeFormikPrefix: string;
}

export const getBitwardenDefaultRightValues = () => ({
  groups: [""],
});

export const getBitwardenPlateformRightsDef = (prefix: string) => ({
  getDefaultRightValues: getBitwardenDefaultRightValues,
  getRequiredRightsComponent: (
    formik: FormikProps<TicketFormData<NewComerTicket>>,
  ) => (
    <BitwardenRequiredRightsForm
      formik={formik}
      attributeFormikPrefix={prefix}
    />
  ),
});

const BitwardenRequiredRightsForm = ({
  formik,
  attributeFormikPrefix,
}: BitwardenRightFormProps) => {
  const { groups } = getIn(formik.values, attributeFormikPrefix);

  const handleAddGroup = () => {
    formik.setFieldValue(`${attributeFormikPrefix}.groups`, [...groups, ""]);
  };

  const handleRemoveGroup = (index: number) => {
    formik.setFieldValue(
      `${attributeFormikPrefix}.groups`,
      groups.filter((_, i) => i !== index),
    );
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.dodgerBlueIcon">
          Bitwarden
        </Typography>
      </Grid>

      {groups.length > 1 && (
        <Grid item xs={12}>
          <List dense sx={{ listStyleType: "disc", py: 0 }}>
            {groups
              .slice(0, groups.length - 1)
              .map((group: string, i: number) => (
                <ListItemText
                  sx={{ display: "list-item", ml: 3 }}
                  primary={
                    <>
                      {group}
                      <IconButton onClick={() => handleRemoveGroup(i)}>
                        <CobaltIcon name="trash-2" />
                      </IconButton>
                    </>
                  }
                  key={i}
                />
              ))}
          </List>
        </Grid>
      )}

      <Grid item xs={6}>
        <Textfield
          label="Ajouter un groupe"
          formik={formik}
          name={`${attributeFormikPrefix}.groups[${groups.length - 1}]`}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={handleAddGroup}>
          <CobaltIcon
            fontSize={24}
            color="cobalt.bleuDigital100"
            name="plus-circle"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default BitwardenRequiredRightsForm;
