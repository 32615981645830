import { Alert as AlertType } from "@/types/others.types";
import { Alert } from "@docaposte-agility/da-design-system";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

interface MyAlertProps {
  alert: AlertType;
  setAlert: (alert: AlertType) => void;
  top: string;
}

const MyAlert = (props: MyAlertProps) => {
  const { alert, setAlert } = props;

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={
          <Alert severity={alert.severity}>
            <Typography component={"span"} variant="body1">
              {alert.text}
            </Typography>
          </Alert>
        }
      />
    </>
  );
};

export default MyAlert;
