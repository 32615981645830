import { Container, Grid, Typography, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Filter from "@/components/Filter";
import { GridData } from "@/components/GridData";
import { useEffect, useState } from "react";
import { useDns } from "@/services/OvhService";
import { FieldColumn } from "@/types/others.types";
import { Dns as DnsType } from "@/types/webservices_ovh_db.types";
import IconButton from "@mui/material/IconButton";
import DnsIcon from "@mui/icons-material/Dns";
import { styled } from "@mui/material/styles";
import { DnsFilters, FiltersTypes } from "@/types/webservices_filters.types";
import ModalDns from "@/components/webservices-ovh/ModalDns";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const DAYS_ORANGE_ALERT = 60;
const DAYS_RED_ALERT = 31;
const DAYS_GREY_ALERT = 0;

const PriorityIcon = styled(Typography)(() => ({
  borderRadius: "2px",
  padding: "2px",
  flex: "35px",
}));

const Dns = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<DnsType | undefined>();
  const [filteredDns, setFilteredDns] = useState<Array<DnsType>>([]);
  const handleOpenModal = (row: DnsType) => {
    setActiveRow(row);
    setOpenModal(true);
  };

  const columns = getColumns(handleOpenModal);

  const [filters, setFilters] = useState<DnsFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as DnsFilters;
  });

  const {
    dns,
    refetch: refetchDns,
    isLoading: isLoadingDns,
  } = useDns({
    onSuccess: (data) => {
      filter(data.data);
    },
  });

  useEffect(() => {
    filter(dns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filter = (dns: DnsType[]) => {
    let filterResults: Array<DnsType> = dns;
    if (filterResults) {
      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter(
            (dns: DnsType) => dns[key] && `${dns[key]}`.includes(filter),
          );
        }
      });
    }
    setFilteredDns(filterResults || []);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            DNS
          </Typography>
        </Grid>
        <Grid item sx={{ height: "24px" }}>
          <Button
            startIcon={<CobaltIcon name="refresh-cw" />}
            onClick={() => refetchDns()}
            variant="text"
            sx={{ height: "24px", pt: 0, pb: 0 }}
          >
            Recharger
          </Button>
        </Grid>
      </Grid>
      <Filter
        fields={columns}
        filters={filters}
        setFilters={setFilters as (filters: FiltersTypes) => void}
      />
      <GridData
        rows={filteredDns || []}
        columns={columns}
        loading={isLoadingDns}
      />
      {activeRow && (
        <ModalDns
          openModal={openModal}
          setOpenModal={setOpenModal}
          dnsData={activeRow}
        />
      )}
    </Container>
  );
};

const calculateRemainingDays = (expirationDate: string): string => {
  const date1: Date = new Date();
  const date2: Date = new Date(expirationDate);
  const diffTime: number = date2.getTime() - date1.getTime();
  const diffDays: number = diffTime / (1000 * 3600 * 24);
  return Math.round(diffDays).toString();
};

const getColumns = (handleOpenModal): Array<FieldColumn> => {
  return [
    {
      field: "domain",
      headerName: "Nom",
      flex: 400,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "expiration",
      headerName: "Date d'expiration",
      flex: 300,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "ovhAccount",
      headerName: "Compte OVH",
      flex: 250,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "days",
      headerName: "Jours restants",
      flex: 300,
      headerAlign: "center",
      align: "center",
      filter: false,
      valueGetter: (params) => {
        const days: string = calculateRemainingDays(params.row.expiration);
        if (parseInt(days) > 0) return `${days} jours restants`;
        else return `Expiré depuis ${days.replace("-", "")} jours`;
      },
    },
    {
      field: "alert",
      headerName: "Priorité",
      flex: 100,
      filter: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const days: number = parseInt(
          calculateRemainingDays(params.row.expiration),
        );
        return (
          <>
            {days > DAYS_ORANGE_ALERT && (
              <PriorityIcon
                sx={{
                  color: "cobalt.white",
                  backgroundColor: "cobalt.greenIcon",
                }}
              >
                OK
              </PriorityIcon>
            )}
            {days <= DAYS_ORANGE_ALERT && days > DAYS_RED_ALERT && (
              <PriorityIcon
                sx={{
                  color: "cobalt.white",
                  backgroundColor: "cobalt.orangeIcon",
                }}
              >
                {`-${DAYS_ORANGE_ALERT}j`}
              </PriorityIcon>
            )}
            {days <= DAYS_RED_ALERT && days > DAYS_GREY_ALERT && (
              <PriorityIcon
                sx={{
                  color: "cobalt.white",
                  backgroundColor: "cobalt.redIcon",
                }}
              >
                {`-${DAYS_RED_ALERT}j`}
              </PriorityIcon>
            )}
            {days <= DAYS_GREY_ALERT && (
              <PriorityIcon
                sx={{
                  color: "cobalt.white",
                  backgroundColor: "cobalt.grey30",
                }}
              >
                EX
              </PriorityIcon>
            )}
          </>
        );
      },
    },

    {
      field: "subdomains",
      headerName: "",
      flex: 32,
      filter: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButton
              aria-label="config button"
              component="span"
              sx={{ color: "cobalt.ultramarine" }}
              onClick={() => handleOpenModal(params.row)}
            >
              <DnsIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
};

export default Dns;
