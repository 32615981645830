import { Typography, Grid, Container, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { FieldColumn, RolesEnum } from "@/types/others.types";
import { Incident, Level, UpdateIncident } from "@/types/incidents.types";
import { GridData } from "@/components/GridData";
import { MoreVertOutlined, Restore } from "@mui/icons-material";
import { useIncidents, useUpdateIncident } from "@/services/IncidentsService";
import { useQueryClient } from "react-query";
import ModalClosedIncident from "@/components/incidents/ModalClosedIncident";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import AuthService from "@/services/AuthService";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const LevelIcon = styled(Typography)(() => ({
  borderRadius: "2px",
  padding: "2px",
  width: "70px",
}));

const ArchivedIncidents = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<Incident | undefined>();

  const queryClient = useQueryClient();

  const { incidents } = useIncidents({
    refetchInterval: 30000,
    onSuccess: (data) =>
      data.data.sort((incidentA, incidentB) =>
        incidentA.created < incidentB.created ? 1 : -1,
      ),
  });

  const { mutate: updateIncident } = useUpdateIncident({
    onSuccess: () => {
      queryClient.invalidateQueries("incidents");
    },
  });

  const handleOpenModal = (activeIncident: Incident) => {
    setActiveRow(activeIncident);
    setOpenModal(true);
  };

  const isAdmin: boolean = AuthService.hasRole(RolesEnum.Admin);

  const columns = useMemo(() => {
    let columns = getColumns(handleOpenModal);
    if (isAdmin) {
      columns = [
        ...columns,
        {
          field: "reopened",
          headerName: "",
          width: 5,
          headerAlign: "center",
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                aria-label="config button"
                component="span"
                sx={{ color: "cobalt.ultramarine" }}
                onClick={() =>
                  updateIncident({
                    id: params.row.id,
                    values: { isActive: true } as UpdateIncident,
                  })
                }
              >
                <Restore />
              </IconButton>
            );
          },
        },
      ];
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const closedIncidents: Incident[] = incidents.filter(
    (incident) => !incident.isActive,
  );

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          margin: "1.5rem",
          height: "fit-content",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "16px" }}
        >
          <Grid item>
            <Typography
              variant="h1"
              color="cobalt.bleuDigital100"
              sx={{ textAlign: "center", fontSize: "32px" }}
            >
              Incidents archivés
            </Typography>
          </Grid>
        </Grid>
        <GridData rows={closedIncidents || []} columns={columns} />
      </Container>
      {activeRow && (
        <ModalClosedIncident
          incident={activeRow}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </>
  );
};

export default ArchivedIncidents;

const getColumns = (handleOpenModal): Array<FieldColumn> => {
  return [
    {
      field: "incidentType",
      headerName: "",
      width: 5,
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) =>
        params.row.date ? (
          <CobaltIcon
            name="clock"
            sx={{
              fontSize: "24px",
            }}
          />
        ) : (
          <CobaltIcon
            name="alert-circle"
            sx={{
              fontSize: "24px",
            }}
          />
        ),
    },
    {
      field: "title",
      headerName: "Titre",
      width: 500,
      headerAlign: "center",
    },
    {
      field: "created",
      headerName: "Date de création",
      width: 170,
      headerAlign: "center",
      valueGetter: (params) => {
        const createdDate: Date = new Date(params.row.created);
        const formatedCreatedDate: string = `${createdDate.toLocaleDateString(
          "fr",
        )} à ${createdDate.getHours()}h${createdDate.getMinutes()}`;
        return formatedCreatedDate;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 400,
      headerAlign: "center",
    },
    {
      field: "level",
      headerName: "Criticité",
      width: 90,
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <LevelIcon
            sx={{
              color: "cobalt.white",
              backgroundColor: switchColor(params.row.level),
            }}
          >
            {params.row.level}
          </LevelIcon>
        );
      },
    },
    {
      field: "more",
      headerName: "",
      width: 5,
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            aria-label="config button"
            component="span"
            sx={{ color: "cobalt.ultramarine" }}
            onClick={() => handleOpenModal(params.row)}
          >
            <MoreVertOutlined />
          </IconButton>
        );
      },
    },
  ];
};

const switchColor = (level: Level) => {
  switch (level) {
    case "Critical":
      return "cobalt.redIcon";
    case "High":
      return "cobalt.orangeIcon";
    case "Medium":
      return "#EAB60A";
    case "Low":
      return "cobalt.dodgerBlueIcon";
    case "Unknown":
      return "cobalt.grey30";
  }
};
