import Filter from "@/components/Filter";
import { GridData } from "@/components/GridData";
import ModalEditIp from "@/components/webservices-ovh/ModalEditIp";
import ModalMoreIp from "@/components/webservices-ovh/ModalMoreIp";
import { useIps } from "@/services/OvhService";
import { FieldColumn } from "@/types/others.types";
import { FiltersTypes, IpFilters } from "@/types/webservices_filters.types";
import { Ip } from "@/types/webservices_ovh_db.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Build,
  FiberManualRecordRounded,
  MoreVertOutlined,
} from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const Ips = () => {
  const [displayIpv6, setDisplayIpv6] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openModalMore, setOpenModalMore] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<Ip | undefined>();
  const [filteredIps, setFilteredIps] = useState<Array<Ip>>([]);

  const handleOpenModal = (row: Ip, edit: boolean) => {
    setActiveRow(row);
    if (edit) setOpenModalEdit(true);
    else setOpenModalMore(true);
  };

  const columns = getColumns(handleOpenModal);

  const [filters, setFilters] = useState<IpFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as IpFilters;
  });

  const {
    ips,
    refetch: refetchIps,
    isLoading: isLoadingIps,
  } = useIps({
    onSuccess: (data) => {
      filter(data.data);
    },
  });

  useEffect(() => {
    filter(ips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, displayIpv6]);

  const filter = (ips: Ip[]) => {
    let filterResults: Array<Ip> = ips;
    if (filterResults) {
      if (!displayIpv6) {
        filterResults = filterResults.filter((ip: Ip) => {
          const res: boolean = ip["ip"].includes(":");
          if (!res) return true;
          return false;
        });
      }

      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter(
            (ip: Ip) => ip[key] && `${ip[key]}`.includes(filter),
          );
        }
      });
    }
    setFilteredIps(filterResults || []);
  };

  const switchIpv6 = () => {
    if (displayIpv6) setDisplayIpv6(false);
    else setDisplayIpv6(true);
    filter(ips);
  };

  const handleCloseModal = async () => {
    setOpenModalEdit(false);
    setActiveRow(undefined);
    await refetchIps();
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            IPs
          </Typography>
        </Grid>
        <Grid item sx={{ height: "24px" }}>
          <Grid container direction="row" alignItems="center">
            <Grid item sx={{ mr: "0.3vw" }}>
              <Typography variant="h5">IPv6</Typography>
            </Grid>
            <Grid item sx={{ mr: "2vw" }}>
              <Switch name="toggle" onChange={switchIpv6} />
            </Grid>
            <Grid item sx={{ height: "24px" }}>
              <Button
                startIcon={<CobaltIcon name="refresh-cw" />}
                onClick={() => refetchIps()}
                variant="text"
                sx={{ height: "24px", pt: 0, pb: 0 }}
              >
                Recharger
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Filter
        fields={columns}
        filters={filters}
        setFilters={setFilters as (filters: FiltersTypes) => void}
      />
      <GridData
        rows={filteredIps || []}
        columns={columns}
        loading={isLoadingIps}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
      />
      {activeRow && (
        <>
          <ModalEditIp
            openModal={openModalEdit}
            handleCloseModal={handleCloseModal}
            ipData={activeRow}
          />
          <ModalMoreIp
            openModal={openModalMore}
            setOpenModal={setOpenModalMore}
            ipData={activeRow}
          />
        </>
      )}
    </Container>
  );
};

const getColumns = (handleOpenModal): Array<FieldColumn> => {
  return [
    {
      field: "hostname",
      headerName: "Nom d'hôte",
      flex: 130,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "domain",
      headerName: "Domaine",
      flex: 210,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "ip",
      headerName: "IP",
      flex: 195,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "type",
      headerName: "Type d'IP",
      flex: 100,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "machineType",
      headerName: "Machine",
      flex: 90,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "vmId",
      headerName: "ID VM",
      flex: 65,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "service",
      headerName: "Service",
      flex: 190,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "description",
      headerName: "Description OVH",
      flex: 180,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "ovhAccount",
      headerName: "Compte OVH",
      flex: 110,
      headerAlign: "center",
      align: "center",
      filter: true,
    },
    {
      field: "isRunning",
      headerName: "",
      flex: 32,
      filter: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FiberManualRecordRounded
            sx={{
              color: params.value ? "cobalt.greenIcon" : "cobalt.grey30",
            }}
          />
        );
      },
    },
    {
      field: "config",
      headerName: "",
      flex: 32,
      filter: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButton
              aria-label="config button"
              component="span"
              sx={{ color: "cobalt.ultramarine" }}
              onClick={() => handleOpenModal(params.row, true)}
            >
              <Build />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "more",
      headerName: "",
      flex: 32,
      filter: false,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <IconButton
              aria-label="config button"
              component="span"
              sx={{ color: "cobalt.ultramarine" }}
              onClick={() => handleOpenModal(params.row, false)}
            >
              <MoreVertOutlined />
            </IconButton>
          </>
        );
      },
    },
  ];
};

export default Ips;
