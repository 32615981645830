import { Grid, Typography } from "@mui/material";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { NewComerTicket, TicketFormData } from "@/types/tickets.types";

interface TeleportRightFormProps {
  formik: FormikProps<TicketFormData<NewComerTicket>>;
  attributeFormikPrefix: string;
}

export const getTeleportPlateformRightsDef = (prefix: string) => ({
  getDefaultRightValues: getTeleportDefaultRightValues,
  getRequiredRightsComponent: (
    formik: FormikProps<TicketFormData<NewComerTicket>>,
  ) => (
    <TeleportRequiredRightsForm
      formik={formik}
      attributeFormikPrefix={prefix}
    />
  ),
});

export const getTeleportDefaultRightValues = () => ({
  machine: "",
});

const TeleportRequiredRightsForm = ({
  formik,
  attributeFormikPrefix,
}: TeleportRightFormProps) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.dodgerBlueIcon">
          Teleport
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Textfield
          label="Machine"
          formik={formik}
          name={`${attributeFormikPrefix}.machine`}
        />
      </Grid>
    </Grid>
  );
};

export default TeleportRequiredRightsForm;
