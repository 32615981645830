import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Box, Tooltip, Typography } from "@mui/material";

interface LabelWithTooltipProps {
  label: string;
  tooltipTitle: React.ReactNode;
}

const LabelWithTooltip = ({ label, tooltipTitle }: LabelWithTooltipProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <CobaltIcon
          name="info-fill"
          sx={{ ml: 0.5, color: "cobalt.userBlue" }}
        />
      </Tooltip>
    </Box>
  );
};

export default LabelWithTooltip;
