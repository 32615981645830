import { useCredentials } from "@/services/OvhService";
import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Filter from "@/components/Filter";
import { GridData } from "@/components/GridData";
import { Credential } from "@/types/webservices_ovh_db.types";
import { FieldColumn } from "@/types/others.types";
import {
  CredentialFilters,
  FiltersTypes,
} from "@/types/webservices_filters.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const Credentials = () => {
  const [filteredCredentials, setFilteredCredentials] = useState<
    Array<Credential>
  >([]);
  const [filters, setFilters] = useState<CredentialFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as CredentialFilters;
  });

  const {
    credentials,
    refetch: refetchCredentials,
    isLoading: isLoadingCredentials,
  } = useCredentials({
    onSuccess: (data) => {
      filter(data.data);
    },
  });

  useEffect(() => {
    filter(credentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, credentials]);

  const filter = (credentials: Credential[]) => {
    let filterResults: Array<Credential> = credentials;
    if (filterResults) {
      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter((credential: Credential) =>
            credential[key].includes(filter),
          );
        }
      });
    }
    setFilteredCredentials(filterResults);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            Credentials
          </Typography>
        </Grid>
        <Grid item sx={{ height: "24px" }}>
          <Button
            startIcon={<CobaltIcon name="refresh-cw" />}
            onClick={() => refetchCredentials()}
            variant="text"
            sx={{ height: "24px", pt: 0, pb: 0 }}
          >
            Recharger
          </Button>
        </Grid>
      </Grid>
      <Filter
        fields={columns}
        filters={filters}
        setFilters={setFilters as (filters: FiltersTypes) => void}
      />
      <GridData
        rows={filteredCredentials || []}
        columns={columns}
        loading={isLoadingCredentials}
      />
    </Container>
  );
};

const columns: Array<FieldColumn> = [
  {
    field: "credentialId",
    headerName: "Credential ID",
    flex: 200,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "applicationId",
    headerName: "Application ID",
    flex: 180,
    filter: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "creation",
    headerName: "Création",
    flex: 230,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "expiration",
    headerName: "Expiration",
    flex: 230,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "lastUse",
    headerName: "Utilisé le",
    flex: 230,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "ovhAccount",
    headerName: "Compte OVH",
    flex: 170,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "state",
    headerName: "Status",
    flex: 170,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
];

export default Credentials;
