import { MenuItem, Select as MuiSelect, SelectProps } from "@mui/material";
import { FormikProps } from "formik";

interface TextfieldProps extends SelectProps {
  name: string;
  formik: FormikProps<any>;
  valuesPossible: string[];
  renderOption?: (value: string) => React.ReactNode;
}

const Select = (props: TextfieldProps) => {
  const { formik, name, valuesPossible, renderOption, ...rest } = props;
  return (
    <MuiSelect
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      MenuProps={MenuProps}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      {...rest}
    >
      {valuesPossible.map((value, key) => (
        <MenuItem key={key} value={value}>
          {renderOption ? renderOption(value) : value}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;

const MenuProps = {
  PaperProps: {
    style: {
      border: "solid 1px",
      borderColor: " cobalt.userBlue",
    },
  },
};
