import { Autocomplete, Grid, Typography } from "@mui/material";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { useMemo } from "react";
import { useProjects } from "@/services/ProjectsService";
import { Project } from "@/types/projects.types";
import {
  EnvOpeningTicket,
  TicketDef,
  TicketFormData,
} from "@/types/tickets.types";
import { envOpeningTicketValidationSchema } from "@/validations/tickets/envOpening.schema";

interface EnvOpeningTicketFromProps {
  formik: FormikProps<TicketFormData<EnvOpeningTicket>>;
}

export const getEnvOpeningDefaultValues = (): EnvOpeningTicket => ({
  firstName: "",
  lastName: "",
  projectIds: [],
  projectNames: [],
  description: "",
});

const serializeEnvOpeningTicket = (data: TicketFormData<EnvOpeningTicket>) => ({
  title: data.title,
  content: {
    firstName: data.content.firstName,
    lastName: data.content.lastName,
    projects: data.content.projectNames,
    description: data.content.description,
  },
});

export const envOpeningTicketDef: TicketDef<EnvOpeningTicket> = {
  label: "Demande d'ouverture d'environnement",
  validationSchema: envOpeningTicketValidationSchema,
  getComponent: (formik: FormikProps<TicketFormData<EnvOpeningTicket>>) => (
    <EnvOpeningTicketForm formik={formik} />
  ),
  defaultValues: getEnvOpeningDefaultValues,
  serializeData: serializeEnvOpeningTicket,
};

const EnvOpeningTicketForm = ({ formik }: EnvOpeningTicketFromProps) => {
  const { projects } = useProjects();

  const projectMap = useMemo(() => {
    return projects.reduce((acc, project: Project) => {
      acc[project.id!] = { id: project.id!, name: project.name };
      return acc;
    }, {});
  }, [projects]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Utilisateur
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Textfield label="Nom" formik={formik} name="content.lastName" />
      </Grid>
      <Grid item xs={6}>
        <Textfield label="Prénom" formik={formik} name="content.firstName" />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Projet(s) associé(s)
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          multiple
          options={Object.keys(projectMap)}
          getOptionLabel={(option) => projectMap[option].name}
          value={formik.values.content.projectIds}
          onChange={(_, val) => {
            formik.setFieldValue("content.projectIds", val);
            formik.setFieldValue(
              "content.projectNames",
              val.map((v) => projectMap[v].name),
            );
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
              "& .MuiAutocomplete-input": { p: "12px 16px" },
            },
          }}
          renderInput={(params) => (
            <Textfield
              {...params}
              formik={formik}
              name="content.projects"
              label="Choix des projets associés"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Description des besoins
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label="Description"
          formik={formik}
          name="content.description"
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default EnvOpeningTicketForm;
