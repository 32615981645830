import { Bar } from "react-chartjs-2";
import { Typography, Box } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { Level14DaysDatasets } from "@/types/incidents.types";
import { useIncidentsStatsGroupByDay } from "@/services/IncidentsService";
import { useMemo } from "react";

const ChartLevel14Days = () => {
  Chart.register(...registerables);

  const { statsByDays } = useIncidentsStatsGroupByDay(14);

  const labels: string[] = useMemo(() => {
    const labels: string[] = statsByDays.map((dayStats) => {
      return dayStats.day || "";
    });
    return labels;
  }, [statsByDays]);

  const datasets: Level14DaysDatasets = useMemo(() => {
    const datasetCritical: number[] = [];
    const datasetHigh: number[] = [];
    const datasetMedium: number[] = [];
    const datasetLow: number[] = [];
    const datasetUnknown: number[] = [];
    statsByDays.forEach((dayStats) => {
      datasetCritical.push(dayStats.count.critical);
      datasetHigh.push(dayStats.count.high);
      datasetMedium.push(dayStats.count.medium);
      datasetLow.push(dayStats.count.low);
      datasetUnknown.push(dayStats.count.unknown);
    });
    return {
      critical: datasetCritical,
      high: datasetHigh,
      medium: datasetMedium,
      low: datasetLow,
      unknown: datasetUnknown,
    };
  }, [statsByDays]);

  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: "Critique",
          data: datasets.critical,
          backgroundColor: "#D82A2A",
        },
        {
          label: "Haut",
          data: datasets.high,
          backgroundColor: "#ED6508",
        },
        {
          label: "Moyen",
          data: datasets.medium,
          backgroundColor: "#EAB60A",
        },
        {
          label: "Faible",
          data: datasets.low,
          backgroundColor: "#6181FF",
        },
        {
          label: "Inconnu",
          data: datasets.unknown,
          backgroundColor: "#C2C5D3",
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets]);

  return (
    <Box sx={{ p: "8px" }}>
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", mb: "8px", color: "cobalt.lynch" }}
      >
        Nombre d'incidents par jour sur les deux dernières semaines
      </Typography>
      <Bar options={options} data={data} />
    </Box>
  );
};

export default ChartLevel14Days;

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
