import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import DateInput from "@/components/DateInput";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import {
  MaterialReturnTicket,
  TicketDef,
  TicketFormData,
} from "@/types/tickets.types";
import { materialReturnValidationSchema } from "@/validations/tickets/materialReturn.schema";

interface MaterialReturnTicketFormProps {
  formik: FormikProps<TicketFormData<MaterialReturnTicket>>;
}

const getMaterialReturnDefaultValues = (): MaterialReturnTicket => ({
  firstName: "",
  lastName: "",
  encounteredIssue: "",
  sinceWhen: new Date(),
  diagnostic: "",
  actionsBeforeDiagnostic: "",
  mustBeReplaced: false,
});

const serializeMaterialReturnData = (
  data: TicketFormData<MaterialReturnTicket>,
) => ({
  title: data.title,
  content: {
    ...data.content,
    sinceWhen: data.content.sinceWhen.toISOString(),
  },
});

export const materialReturnTicketDef: TicketDef<MaterialReturnTicket> = {
  label: "Retour de matériel",
  validationSchema: materialReturnValidationSchema,
  getComponent: (formik: FormikProps<TicketFormData<MaterialReturnTicket>>) => (
    <MaterialReturnTicketForm formik={formik} />
  ),
  defaultValues: getMaterialReturnDefaultValues,
  serializeData: serializeMaterialReturnData,
};

const MaterialReturnTicketForm = ({
  formik,
}: MaterialReturnTicketFormProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Utilisateur
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Textfield formik={formik} name="content.lastName" label="Nom" />
      </Grid>
      <Grid item xs={6}>
        <Textfield formik={formik} name="content.firstName" label="Prénom" />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Problème
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Textfield
          formik={formik}
          name="content.encounteredIssue"
          label="Problème rencontré"
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          formik={formik}
          name="content.sinceWhen"
          fieldProps={{ label: "Depuis" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Diagnostic
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          formik={formik}
          name="content.diagnostic"
          label="Diagnostic"
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Textfield
          formik={formik}
          name="content.actionsBeforeDiagnostic"
          label="Quelles manipulations ont conduit à ce diagnostic ?"
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label="Doit être remplacé"
          sx={{ ml: 0 }}
          control={
            <Checkbox
              checked={formik.values.content.mustBeReplaced}
              name="content.mustBeReplaced"
              onChange={formik.handleChange}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default MaterialReturnTicketForm;
