import Ips from "@/routes/Ips";
import HomePage from "@/routes/HomePage";
import Servers from "@/routes/Servers";
import Dns from "@/routes/Dns";
import Nas from "@/routes/Nas";
import Applications from "@/routes/Applications";
import Credentials from "@/routes/Credentials";
import Incidents from "@/routes/Incidents";
import WithMenu from "./WithMenu";
import PrivateRoute from "@/routes/PrivateRoute";
import { Routes, Route, Navigate } from "react-router-dom";
import { RolesEnum } from "@/types/others.types";
import ArchivedIncidents from "@/routes/ArchivedIncidents";
import Project from "@/routes/Project";
import Projects from "@/routes/Projects";
import NewTicket from "@/routes/NewTicket";
import Tools from "@/routes/Tools";

const RoutesProvider = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <HomePage />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="serveurs"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Servers />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route path="*" element={<Navigate to="/" />} />
    <Route
      path="ips"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Ips />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="credentials"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Credentials />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="applications"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Applications />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="nas"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Nas />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="dns"
      element={
        <PrivateRoute authorizedRole={RolesEnum.Admin}>
          <WithMenu>
            <Dns />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="suivi-incidents"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <Incidents />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="incidents-archives"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <ArchivedIncidents />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="ticket"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <NewTicket />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="outils"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <Tools />
          </WithMenu>
        </PrivateRoute>
      }
    />
    {/* <Route
      path="mes-informations"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <MyInformations />
          </WithMenu>
        </PrivateRoute>
      }
    /> */}
    <Route
      path="projets"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <Projects />
          </WithMenu>
        </PrivateRoute>
      }
    />
    <Route
      path="projets/:id"
      element={
        <PrivateRoute authorizedRole={RolesEnum.All}>
          <WithMenu>
            <Project />
          </WithMenu>
        </PrivateRoute>
      }
    />
  </Routes>
);

export default RoutesProvider;
