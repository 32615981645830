import { Badge, BadgeProps, badgeClasses } from "@mui/material";

interface SelectLevelBadgeProps extends BadgeProps {
  children: React.ReactNode;
  colorOverride: string;
}

const SelectLevelBadge = ({
  children,
  colorOverride,
  ...props
}: SelectLevelBadgeProps) => {
  return (
    <Badge
      variant="dot"
      showZero
      color="error"
      {...props}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        [`& .${badgeClasses.badge}`]: {
          backgroundColor: colorOverride,
          top: "50%",
          left: "0",
        },
        pl: "8px",
        ...props.sx,
      }}
    >
      {children}
    </Badge>
  );
};
export default SelectLevelBadge;
