import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useUpdateSubDomain } from "@/services/OvhService";
import { ObjectUpdateSubDomain } from "@/types/others.types";
import { Dns } from "@/types/webservices_ovh_db.types";
import Textfield from "@/components/Textfield";
import { subDomainValidationSchema } from "@/validations/webservices-ovh/subdomain.schema";

interface SnackbarEditSubDomainProps {
  open: boolean;
  top: string;
  handleClose: (cancel: boolean, error?: boolean) => void;
  values: ObjectUpdateSubDomain;
  idSubDomain: number;
  dns: Dns;
}

const SnackbarEditSubDomain = (props: SnackbarEditSubDomainProps) => {
  const { open, top, handleClose, values, dns, idSubDomain } = props;

  const queryClient = useQueryClient();
  const theme = useTheme();
  const snackbarBreakpoint = useMediaQuery(theme.breakpoints.down(1600));

  const { mutate: updateSubDomain } = useUpdateSubDomain({
    onSuccess: () => {
      queryClient.invalidateQueries("subDomains");
      handleClose(false, false);
    },
    onError: () => {
      handleClose(false, true);
    },
  });

  const doUpdateSubDomain = async (newValues: ObjectUpdateSubDomain) => {
    await updateSubDomain({
      idRecord: idSubDomain,
      values: newValues,
      zone: dns.domain,
      ovhAccount: dns.ovhAccount,
    });
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      subDomain: values.subDomain,
      ttl: values.ttl.toString(),
      target: values.target,
    },
    validationSchema: subDomainValidationSchema,
    onSubmit: (validateValues) => {
      const newValues: ObjectUpdateSubDomain = {
        subDomain: validateValues.subDomain,
        ttl: parseInt(validateValues.ttl),
        target: validateValues.target,
      };
      doUpdateSubDomain(newValues);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: snackbarBreakpoint ? "center" : "right",
        }}
        open={open}
        sx={{
          position: "absolute",
          right: "-375px !important",
          top: `${top} !important`,
        }}
        message={
          <>
            <Alert onClose={() => handleClose(true)}>
              <AlertTitle>Modification d'un sous-domaine</AlertTitle>

              <Typography variant="body2">Nouveau sous-domaine :</Typography>
              <Textfield name="subDomain" formik={formik} fullWidth />
              <Typography variant="body2">Nouveau TTL :</Typography>
              <Textfield
                name="ttl"
                formik={formik}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                fullWidth
              />
              <Typography variant="body2">Nouvelle cible :</Typography>
              <Textfield name="target" formik={formik} fullWidth />
            </Alert>
            <Divider light />
          </>
        }
        action={
          <>
            <Button variant="text" type="submit">
              Confirmer
            </Button>
            <Divider light orientation="vertical" />
            <Button onClick={() => handleClose(true)} variant="text">
              Annuler
            </Button>
            <Divider light />
          </>
        }
      />
    </form>
  );
};

export default SnackbarEditSubDomain;
