import axios, { AxiosResponse } from "axios";
import { mapProjectFromApiToProject } from "@/mappers/projects.mappers";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  CreateProject,
  ImageType,
  ProjectFromApi,
  UpdateProject,
} from "@/types/projects.types";

const endpoint: string | undefined = window.__ENV__.REACT_APP_ENDPOINT_BACK;

export const useProjects = (
  config: UseQueryOptions<
    AxiosResponse<ProjectFromApi[]>,
    unknown,
    AxiosResponse<ProjectFromApi[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["projects"],
    () => axios.get(`${endpoint}/projects`),
    { ...config },
  );
  const projects = data?.data.map((p) => mapProjectFromApiToProject(p)) || [];
  return { projects, ...rest };
};

export const useProject = (
  projectId: string,
  config: UseQueryOptions<
    AxiosResponse<ProjectFromApi>,
    unknown,
    AxiosResponse<ProjectFromApi>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    [`project.${projectId}`],
    () => axios.get(`${endpoint}/projects/${projectId}`),
    { ...config },
  );
  const project = data && mapProjectFromApiToProject(data.data);
  return { project, ...rest };
};

export const useUpdateProject = (
  config: UseMutationOptions<
    void,
    unknown,
    { id: string; values: UpdateProject }
  > = {},
) => {
  return useMutation(
    ({ id, values }) => {
      const form = new FormData();
      Object.entries(values).forEach(
        ([name, val]) =>
          val !== null &&
          val !== undefined &&
          form.append(name, val.toString()),
      );
      if (values.image) {
        form.set("image", imageTypeToBlob(values.image));
      }

      return axios.patch(`${endpoint}/projects/${id}`, form);
    },
    { ...config },
  );
};

const imageTypeToBlob = (image: ImageType, sliceSize = 512) => {
  const rawImage = atob(image.data);
  const bytes = new Array<Uint8Array>(rawImage.length);

  for (let offset = 0; offset < rawImage.length; offset += sliceSize) {
    const slice = rawImage.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    bytes[offset / sliceSize] = byteArray;
  }

  return new Blob(bytes, {
    type: "image/png",
  });
};

export const useCreateProject = (
  config: UseMutationOptions<void, unknown, { values: CreateProject }> = {},
) => {
  return useMutation(
    ({ values }) => {
      const form = new FormData();
      Object.entries(values).forEach(
        ([name, val]) =>
          val !== null &&
          val !== undefined &&
          form.append(name, val.toString()),
      );
      if (!values.image || values.image.data === "") {
        form.set("image", new Blob([], { type: "image/png" }));
      } else {
        form.set("image", imageTypeToBlob(values.image));
      }

      return axios.post(`${endpoint}/projects/`, form);
    },
    { ...config },
  );
};
