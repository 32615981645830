import { Tool } from "@/types/tools.types";
import { CobaltIcon, Dialog } from "@docaposte-agility/da-design-system";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import Textfield from "../Textfield";
import ButtonWithConfirm from "../ButtonWithConfirm";
import { toolValidationSchema } from "@/validations/tool.schema";
import { useCreateTool, useUpdateTool } from "@/services/ToolService";
import { useQueryClient } from "react-query";
import { getModifiedFields } from "@/utils/tools.utils";

interface ModalEditToolProps {
  mode: "edit" | "add";
  tool?: Tool;
  sectionNames: Array<string>;
  onClose: () => void;
}

const ModalEditTool = ({
  tool,
  onClose,
  mode,
  sectionNames,
}: ModalEditToolProps) => {
  const queryClient = useQueryClient();
  const [requestSent, setRequestSent] = useState(false);
  const initialValues: Tool = useMemo(
    () =>
      tool || {
        name: "",
        description: "",
        link: "",
        section: "",
        contact_label: "",
        contact_link: "",
        logo: "",
        manual_link: "",
      },
    [tool],
  );

  const {
    mutate: createTool,
    isLoading: isCreateLoading,
    isSuccess: isCreatedSuccessful,
  } = useCreateTool({
    onSuccess: () => queryClient.invalidateQueries("tools"),
  });

  const {
    mutate: updateTool,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccessful,
  } = useUpdateTool({
    onSuccess: () => queryClient.invalidateQueries("tools"),
  });

  const handleEditSubmit = (values: Tool) => {
    if (mode === "add") {
      createTool({ values });
      setRequestSent(true);
    } else if (mode === "edit" && values.id) {
      updateTool({ values: getModifiedFields(tool!, values), id: tool?.id! });
      setRequestSent(true);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: toolValidationSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: handleEditSubmit,
  });

  return (
    <Dialog
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {mode === "edit" ? "Modifier" : "Ajouter"} un outil
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!requestSent ? (
              <>
                <Grid item xs={12} md={6}>
                  <Textfield formik={formik} label="Nom" name="name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={sectionNames}
                    value={formik.values.section}
                    onChange={(_, value) =>
                      formik.setFieldValue("section", value)
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        p: 0,
                        "& .MuiAutocomplete-input": { p: "12px 16px" },
                      },
                    }}
                    renderInput={(params) => (
                      <Textfield
                        {...params}
                        formik={formik}
                        name="section"
                        label="Section"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Textfield
                    formik={formik}
                    label="Description"
                    name="description"
                    multiline
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        p: 0,
                      },
                    }}
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Textfield formik={formik} label="Lien" name="link" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Textfield
                    formik={formik}
                    label="Lien vers le logo"
                    name="logo"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Textfield
                    formik={formik}
                    label="Lien vers le manuel"
                    name="manual_link"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Contact</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Textfield
                    formik={formik}
                    label="Lien vers le contact"
                    name="contact_link"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Textfield
                    formik={formik}
                    label="Texte à afficher"
                    name="contact_label"
                  />
                </Grid>
              </>
            ) : (
              <Stack spacing={2} alignItems="center" mx="auto">
                {isCreateLoading || isUpdateLoading ? (
                  <>
                    <Typography>
                      {mode === "add" ? "Ajout" : "Mise à jour"} de l'outil...
                    </Typography>
                    <CircularProgress />
                  </>
                ) : isUpdateSuccessful || isCreatedSuccessful ? (
                  <>
                    <CobaltIcon
                      fontSize={64}
                      name="check-circle"
                      color="cobalt.greenIcon"
                    />
                    <Typography>
                      L'outil a bien été{" "}
                      {mode === "add" ? "ajouté" : "mis à jour"}
                    </Typography>
                  </>
                ) : (
                  <>
                    <CobaltIcon
                      fontSize={64}
                      name="alert-circle"
                      color="cobalt.redIcon"
                    />
                    <Typography>
                      Une erreur est survenue lors de{" "}
                      {mode === "add" ? "l'ajout" : "la mise à jour"} du projet.
                    </Typography>
                  </>
                )}
              </Stack>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!requestSent ? (
            <ButtonWithConfirm
              type="submit"
              confirmText={`Voulez-vous vraiment ${
                mode === "add" ? "ajouter" : "modifier"
              } cet outil ?`}
            >
              Submit
            </ButtonWithConfirm>
          ) : (
            <Button onClick={onClose}>Fermer</Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditTool;
