import * as Yup from "yup";

export const incidentValidationSchema = Yup.object({
  title: Yup.string().required("Le titre est requis !"),
  level: Yup.string().required("La criticité est requise !"),
  type: Yup.string().required("Le type est requis !"),
  author: Yup.string().required("L'auteur est requis !"),
  resolutionTime: Yup.string().required("Le temps de résolution est requis !"),
  link: Yup.string().optional().url("Le lien doit être une URL valide !"),
  impactedEnv: Yup.string().required(
    "Les environnements impactés sont requis !",
  ),
});
