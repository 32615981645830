import * as Yup from "yup";

export const bugReportValidationSchema = Yup.object().shape({
  project: Yup.string().required("Le nom du projet est requis"),
  priority: Yup.string().required("La priorité est requise"),
  description: Yup.string().required("La description est requise"),
  stepsToReproduce: Yup.array()
    .transform((value, _) => {
      value.pop();
      return value;
    })
    .min(1, "Au moins une étape est requise")
    .of(Yup.string().required("L'étape est requise")),
  expectedResults: Yup.string().required("Le résultat attendu est requis"),
  actualResults: Yup.string().required("Le résultat obtenu est requis"),
  context: Yup.string().required("Le contexte est requis"),
  environment: Yup.string().required("L'environnement est requis"),
});
