import { GridColDef } from "@mui/x-data-grid";
import { JSX } from "react";

export type Severity = "error" | "success" | "info" | "warning" | undefined;

export interface Alert {
  open: boolean;
  severity: Severity;
  text: string;
}

export type FieldColumn = GridColDef & { filter?: boolean };

export interface ValuesEditIp {
  isRunning: boolean;
  service: string;
  hostname: string;
  domain: string;
  vmId: number;
  machineType: string;
  description: string;
}

export interface SnackbarAttributesIp {
  title: string;
  text: string;
  top: string;
}

export interface ObjectUpdateSubDomain {
  subDomain: string;
  target: string;
  ttl: number;
}

export interface SnackbarEditSubDomainObject {
  open: boolean;
  idSubDomain: number;
  subDomain: string;
  ttl: number;
  target: string;
}

export interface NewValuesObject {
  isRunning?: boolean;
  service?: string;
  hostname?: string;
  domain?: string;
  vmId?: number;
  machineType?: string;
  description?: string;
  ovhAccount?: string;
}

export interface SnackbarDeleteSubDomainObject {
  open: boolean;
  idSubDomain: number;
}

export interface RouteMenu {
  label: JSX.Element | string;
  route: string;
  icon: React.ReactNode;
}

export enum RolesEnum {
  User = "user",
  Admin = "admin",
  All = "all",
}

export enum TitleLevel {
  LEVEL1 = 1,
  LEVEL2 = 2,
}
