import * as Yup from "yup";

const REGEX_IS_DOMAIN =
  // eslint-disable-next-line no-useless-escape
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export const ipValidationSchema = Yup.object({
  hostname: Yup.string(),
  domain: Yup.string().matches(
    REGEX_IS_DOMAIN,
    "Le domaine n'est pas valide !",
  ),
  vmId: Yup.number()
    .typeError("L'ID de la VM doit être un nombre !")
    .max(99999999, "L'ID de la VM ne peut pas dépasser 99999999"),
  machineType: Yup.string(),
  description: Yup.string(),
});
