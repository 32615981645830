import axios, { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  CreateIncident,
  Incident,
  UpdateIncident,
} from "@/types/incidents.types";
import { StatsObject } from "@/types/incidents.types";

const endpoint: string | undefined = window.__ENV__.REACT_APP_ENDPOINT_BACK;

export const useIncidents = (
  config: UseQueryOptions<
    AxiosResponse<Incident[]>,
    unknown,
    AxiosResponse<Incident[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["incidents"],
    () => axios.get(`${endpoint}/incidents`),
    { ...config },
  );
  const incidents = data?.data || [];
  incidents.forEach((incident) => {
    if (incident.date) incident.date = new Date(incident.date);
  });

  return { incidents, ...rest };
};

export const useUpdateIncident = (
  config: UseMutationOptions<
    void,
    unknown,
    { id: string; values: UpdateIncident }
  > = {},
) => {
  return useMutation(
    ({ id, values }) => {
      return axios.patch(`${endpoint}/incidents/${id}`, values);
    },
    { ...config },
  );
};

export const useCreateIncident = (
  config: UseMutationOptions<void, unknown, { values: CreateIncident }> = {},
) => {
  return useMutation(
    ({ values }) => {
      return axios.post(`${endpoint}/incidents`, values);
    },
    { ...config },
  );
};

export const useIncidentsStats = (
  nbDays: number,
  config: UseQueryOptions<
    AxiosResponse<StatsObject>,
    unknown,
    AxiosResponse<StatsObject[]>,
    Array<string>
  > = {},
) => {
  const { data, ...rest } = useQuery(
    ["incidentsStats", nbDays.toString()],
    () =>
      axios.get(`${endpoint}/incidents/stats`, {
        params: { nbDays, groupByDate: false },
      }),
    { ...config },
  );
  const stats = data?.data?.length ? data?.data[0] : undefined;
  return { stats, ...rest };
};

export const useIncidentsStatsGroupByDay = (
  nbDays: number,
  config: UseQueryOptions<
    AxiosResponse<StatsObject[]>,
    unknown,
    AxiosResponse<StatsObject[]>,
    Array<string>
  > = {},
) => {
  const { data, ...rest } = useQuery(
    ["incidentsStatsGroupByDay", nbDays.toString()],
    () =>
      axios.get(`${endpoint}/incidents/stats`, {
        params: { nbDays: nbDays, groupByDate: true },
      }),
    { ...config },
  );
  const statsByDays = data?.data || [];
  return { statsByDays, ...rest };
};
