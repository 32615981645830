import { levelToColor, mapLevelToFrench } from "@/mappers/incidents.mappers";
import { useUpdateIncident } from "@/services/IncidentsService";
import { Incident, UpdateIncident } from "@/types/incidents.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Edit, Lock } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import ModalIncident from "./ModalIncident";

interface CardIncidentProps {
  values: Incident;
  isAdmin: boolean;
}

const CardIncident = (props: CardIncidentProps) => {
  const {
    id,
    title,
    level,
    type,
    author,
    resolutionTime,
    impactedEnv,
    description,
    date,
    link,
    created,
  } = props.values;
  const { values, isAdmin } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const formatDate: string | null = useMemo(() => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY");
    } else return null;
  }, [date]);

  const formatHour: string | null = useMemo(() => {
    if (date) {
      return dayjs(date).format("HH:mm");
    } else return null;
  }, [date]);

  const queryClient = useQueryClient();

  const { mutate: updateIncident } = useUpdateIncident({
    onSuccess: () => {
      queryClient.invalidateQueries("incidents");
    },
  });

  const createdDate: Date = new Date(created);
  const formatedCreatedDate: string =
    dayjs(createdDate).format("DD/MM/YYYY à HH:mm");
  return (
    <Accordion
      square
      sx={{
        border: "none",
        borderLeft: `6px ${date ? "dashed" : "solid"}`,
        borderLeftColor: levelToColor(level),
        color: "cobalt.ultramarine",
        borderRadius: "6px",
        "&:before": {
          backgroundColor: "transparent !important",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <CobaltIcon name="chevron-down" sx={{ color: "cobalt.lynch" }} />
        }
      >
        <Box
          sx={{
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            mr: "16px",
          }}
        >
          {date ? (
            <CobaltIcon
              name="clock"
              sx={{
                color: levelToColor(level),
                fontSize: "38px",
              }}
            />
          ) : (
            <CobaltIcon
              name="alert-circle"
              sx={{
                color: levelToColor(level),
                fontSize: "38px",
              }}
            />
          )}
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h2">{title}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              {author}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <AttributeIncident
              title="Temps de résolution estimée :"
              value={resolutionTime}
              height={20}
            />
            {date ? (
              <AttributeIncident
                title="Date :"
                value={`${formatDate} à ${formatHour}`}
                height={20}
              />
            ) : (
              <AttributeIncident
                title="Environnements impactés : "
                value={impactedEnv}
                height={20}
              />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: "0 16px 8px 16px" }}>
        <Typography variant="body1" sx={{ mb: "8px" }}>
          {description}
        </Typography>
        <Grid container>
          <Grid item md={isAdmin ? 9 : 12}>
            <AttributeIncident title="Créé le : " value={formatedCreatedDate} />
            <AttributeIncident
              title="Criticité : "
              value={mapLevelToFrench(level)}
            />
            <AttributeIncident title="Type d'indisponibilité : " value={type} />
            {date && (
              <AttributeIncident
                title="Environnements impactés : "
                value={impactedEnv}
              />
            )}
            {link && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  sx={{ color: "cobalt.lynch", mr: "4px" }}
                >
                  Lien :
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  sx={{ color: "cobalt.ultramarine", p: "0" }}
                  href={link}
                >
                  {link}
                </Button>
              </Box>
            )}
          </Grid>
          {isAdmin && (
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                startIcon={<Edit />}
                variant="text"
                size="small"
                sx={{
                  padding: "0.5vw",
                  color: "cobalt.userBlue",
                  "&:hover": {
                    backgroundColor: "cobalt.bleuDigital05",
                  },
                }}
                onClick={() => setOpenModal(true)}
              >
                Modifier
              </Button>
              <Button
                startIcon={<Lock />}
                variant="text"
                size="small"
                sx={{
                  color: "cobalt.userBlue",
                  padding: "0.5vw",
                  "&:hover": {
                    backgroundColor: "cobalt.bleuDigital05",
                  },
                }}
                onClick={() =>
                  updateIncident({
                    id: id,
                    values: { isActive: false } as UpdateIncident,
                  })
                }
              >
                Cloturer
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
      {openModal && (
        <ModalIncident
          values={values}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          scheduledIncident={date ? true : false}
        />
      )}
    </Accordion>
  );
};

interface AttributeIncidentProps {
  title: string;
  value: string;
  height?: number;
}

const AttributeIncident = (props: AttributeIncidentProps) => {
  const { title, value, height } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: height ? `${height}px` : "32px",
      }}
    >
      <Typography variant="h5" sx={{ color: "cobalt.lynch", mr: "4px" }}>
        {title}
      </Typography>
      <Typography variant="h5" sx={{ color: "cobalt.ultramarine" }}>
        {value}
      </Typography>
    </Box>
  );
};

export default CardIncident;
