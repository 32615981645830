import { Stack, Typography, Button, Box } from "@mui/material";
import WithMenu from "@/components/WithMenu";
import { useNavigate } from "react-router-dom";

const ForbidenPage = () => {
  const navigate = useNavigate();

  return (
    <WithMenu>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack alignItems="center" justifyContent="center" spacing={3}>
          <img
            src="/images/not-autorized.png"
            alt="Non autorisé"
            style={{ width: "300px" }}
          />
          <Typography variant="h2">
            Vous n'êtes pas autorisé à accéder à cette page
          </Typography>
          <Button onClick={() => navigate("/")}>Retour à l'accueil</Button>
        </Stack>
      </Box>
    </WithMenu>
  );
};
export default ForbidenPage;
