import { TextField as MuiTextField } from "@mui/material";
import { FormikProps, getIn } from "formik";

interface TextfieldProps {
  name: string;
  formik: FormikProps<any>;
  multiline?: boolean;
  [x: string]: any;
}

const Textfield = (props: TextfieldProps) => {
  const { formik, name, ...rest } = props;

  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  return (
    <MuiTextField
      type="text"
      name={name}
      value={value}
      onChange={formik.handleChange}
      error={touched && Boolean(error)}
      helperText={error?.toString()}
      fullWidth
      {...rest}
    />
  );
};

export default Textfield;
