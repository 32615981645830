import { Dialog } from "@docaposte-agility/da-design-system";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Container,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Filter from "@/components/Filter";
import ButtonWithConfirm from "@/components/ButtonWithConfirm";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { dnsValdidationSchema } from "@/validations/webservices-ovh/dns.schema";
import {
  useAddSubDomain,
  useDeleteSubDomain,
  useSubDomains,
} from "@/services/OvhService";
import {
  Alert,
  FieldColumn,
  Severity,
  SnackbarEditSubDomainObject,
} from "@/types/others.types";
import {
  FiltersTypes,
  SubDomainFilters,
} from "@/types/webservices_filters.types";
import { Dns, FieldTypeEnum, Record } from "@/types/webservices_ovh_db.types";
import { GridData } from "@/components/GridData";
import MyAlert from "@/components/MyAlert";
import Select from "@/components/Select";
import Textfield from "@/components/Textfield";
import SnackbarEditSubDomain from "./SnackbarEditSubDomain";

interface ModalDnsProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  dnsData: Dns;
}

const DEFAULT_VALUES_EDIT: SnackbarEditSubDomainObject = {
  open: false,
  idSubDomain: -1,
  subDomain: "",
  ttl: -1,
  target: "",
};

const DEFAULT_VALUES_ALERT: Alert = {
  open: false,
  severity: "error" as Severity,
  text: "",
};

const ModalDns = (props: ModalDnsProps) => {
  const { openModal, setOpenModal, dnsData } = props;

  const [snackbarEditSubDomain, setSnackbarEditSubDomain] =
    useState<SnackbarEditSubDomainObject>(DEFAULT_VALUES_EDIT);
  const [alert, setAlert] = useState<Alert>(DEFAULT_VALUES_ALERT);
  const [filteredSubDomains, setFilteredSubDomains] = useState<Record[]>([]);

  const doDeleteSubDomain = (idSubDomain) => {
    deleteSubDomain({
      zone: dnsData.domain,
      idRecord: idSubDomain,
      ovhAccount: dnsData.ovhAccount,
    });
  };

  const columns = getColumns(setSnackbarEditSubDomain, doDeleteSubDomain);

  const [filters, setFilters] = useState<SubDomainFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as SubDomainFilters;
  });

  const queryClient = useQueryClient();

  const { subDomains, isLoading: isLoadingSubDomains } = useSubDomains(
    dnsData.domain,
    dnsData.ovhAccount,
    {
      enabled: dnsData.domain !== "",
      onSuccess: (data) => {
        filter(data.data);
      },
    },
  );

  const { mutate: deleteSubDomain } = useDeleteSubDomain({
    onSuccess: () => {
      queryClient.invalidateQueries("subDomains");
      setAlert({
        open: true,
        severity: "success",
        text: "Le sous-domaine a bien été supprimé",
      });
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur : le sous-domaine n'a pas été supprimé",
      });
    },
  });

  useEffect(() => {
    setSnackbarEditSubDomain(DEFAULT_VALUES_EDIT);
  }, [openModal]);

  useEffect(() => {
    filter(subDomains);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filter = (subDomains: Record[]) => {
    let filterResults: Array<Record> = subDomains;
    if (filterResults) {
      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter((subdomain: Record) => {
            if (subdomain[key]) return `${subdomain[key]}`.includes(filter);
            return false;
          });
        }
      });
    }
    setFilteredSubDomains(filterResults || []);
  };

  const handleCloseSnackbarEdit = (cancel: boolean, error?: boolean) => {
    if (!cancel) {
      if (!error) {
        setAlert({
          open: true,
          severity: "success",
          text: "Les modifications ont bien été effectuées",
        });
      } else {
        setAlert({
          open: true,
          severity: "error",
          text: "Erreur : les modifications n'ont pas été effectuées",
        });
      }
    }
    setSnackbarEditSubDomain(DEFAULT_VALUES_EDIT);
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="md"
      >
        <DialogTitle sx={{ minWidth: "60vw" }}>
          <div>
            <Typography variant="h4">{`DNS | ${dnsData.domain}`}</Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="h4"
            sx={{ mb: "1vh", color: "cobalt.bleuDigital100" }}
          >
            Sous-domaines
          </Typography>
          <Filter
            fields={columns}
            filters={filters}
            setFilters={setFilters as (filters: FiltersTypes) => void}
          />
          <GridData
            rows={filteredSubDomains || []}
            columns={columns}
            fixedSize={5}
            loading={isLoadingSubDomains}
          />
          <AddSubDomain dns={dnsData} />
          <SnackbarEditSubDomain
            open={snackbarEditSubDomain.open}
            top={"360px"}
            handleClose={handleCloseSnackbarEdit}
            dns={dnsData}
            idSubDomain={snackbarEditSubDomain.idSubDomain}
            values={{
              subDomain: snackbarEditSubDomain.subDomain,
              ttl: snackbarEditSubDomain.ttl,
              target: snackbarEditSubDomain.target,
            }}
          />
          <MyAlert alert={alert} setAlert={setAlert} top="450px" />
        </DialogContent>
      </Dialog>
    </>
  );
};

const getColumns = (
  setSnackbarEditSubDomain: (value: SnackbarEditSubDomainObject) => void,
  removeDomain: (value: string) => void,
): Array<FieldColumn> => {
  return [
    {
      field: "subDomain",
      headerName: "Sous-domaine",
      width: 370,
      headerAlign: "center",
      filter: true,
    },
    {
      field: "fieldType",
      headerName: "Type",
      width: 100,
      headerAlign: "center",
      filter: true,
    },
    {
      field: "ttl",
      headerName: "TTL",
      width: 90,
      headerAlign: "center",
      filter: true,
      type: "number",
    },
    {
      field: "target",
      headerName: "Cible",
      width: 370,
      headerAlign: "center",
      filter: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 100,
      filter: false,
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton
            aria-label="config button"
            component="span"
            sx={{ color: "cobalt.ultramarine" }}
            onClick={() =>
              setSnackbarEditSubDomain({
                open: true,
                idSubDomain: params.row.id,
                subDomain: params.row.subDomain,
                ttl: params.row.ttl,
                target: params.row.target,
              })
            }
          >
            <Edit />
          </IconButton>
          <ButtonWithConfirm
            icon
            onConfirm={() => removeDomain(params.row.id)}
            confirmText="Voulez-vous vraiment supprimer ce sous-domaine ?"
            aria-label="config button"
            sx={{ color: "cobalt.ultramarine" }}
          >
            <Delete />
          </ButtonWithConfirm>
        </>
      ),
    },
  ];
};

export default ModalDns;

const valuesPossibleFieldType = [
  "A",
  "AAAA",
  "CAA",
  "CNAME",
  "DKIM",
  "DMARC",
  "DNAME",
  "LOC",
  "MX",
  "NAPTR",
  "NS",
  "PTR",
  "SPF",
  "SRV",
  "SSHFP",
  "TLSA",
  "TXT",
];

interface AddSubDomainProps {
  dns: Dns;
}

const AddSubDomain = (props: AddSubDomainProps) => {
  const { dns } = props;
  const [alert, setAlert] = useState<Alert>({
    open: false,
    severity: "error",
    text: "",
  });

  const queryClient = useQueryClient();

  const { mutate: addSubDomain } = useAddSubDomain({
    onSuccess: () => {
      queryClient.invalidateQueries("subDomains");
      setAlert({
        open: true,
        severity: "success",
        text: "Le sous-domaine a bien été créé",
      });
      formik.resetForm();
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur : le sous-domaine n'a pas été ajouté",
      });
    },
  });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      subDomain: "",
      ttl: "",
      target: "",
      fieldType: "",
    },
    validationSchema: dnsValdidationSchema,
    onSubmit: async (validateValues) => {
      const newRecord: Record = {
        fieldType: validateValues.fieldType as unknown as FieldTypeEnum,
        subDomain: validateValues.subDomain,
        target: validateValues.target,
        ttl: parseInt(validateValues.ttl),
      };
      addSubDomain({
        zone: dns.domain,
        record: newRecord,
        ovhAccount: dns.ovhAccount,
      });
    },
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
        backgroundColor: "cobalt.white",
        margin: "2vh 0 0.5vh 0",
        borderRadius: "4px",
        padding: "1vh",
        boxShadow: "0 4px 8px rgb(102 109 146 / 8%)",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container alignItems="center" spacing={1} sx={{ padding: 0 }}>
          <Grid item xs={4}>
            <Textfield
              name="subDomain"
              placeholder="Sous-domaine"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              name="fieldType"
              formik={formik}
              placeholder="Type"
              sx={{ width: "100%" }}
              valuesPossible={valuesPossibleFieldType}
            />
          </Grid>
          <Grid item xs={1}>
            <Textfield name="ttl" placeholder="TTL" formik={formik} />
          </Grid>
          <Grid item xs={4}>
            <Textfield name="target" placeholder="Cible" formik={formik} />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              type="submit"
              color="primary"
              sx={{
                backgroundColor: "cobalt.greenIcon",
                color: "cobalt.light",
                margin: "0 1vw 0 1vw",
                "&:hover, &.Mui-focusVisible": {
                  backgroundColor: "cobalt.greenIcon",
                },
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      <MyAlert alert={alert} setAlert={setAlert} top="650px" />
    </Container>
  );
};
