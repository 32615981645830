import { RouteMenu } from "@/types/others.types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemButton,
  accordionClasses,
  accordionSummaryClasses,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { NavigateFunction } from "react-router";
import { useNavigate } from "react-router-dom";

interface MenuSectionProps {
  title: string;
  titlesAndRoutesButtons: Array<RouteMenu>;
  open: boolean;
  widthItem: number;
  expandedSection: string;
  setExpandedSection: (value: string) => void;
}

const OpenMenuSection = (props: MenuSectionProps) => {
  const {
    title,
    titlesAndRoutesButtons,
    open,
    widthItem,
    expandedSection,
    setExpandedSection,
  } = props;
  const navigate: NavigateFunction = useNavigate();

  const handleChange = () => {
    setExpandedSection(expandedSection !== title ? title : "");
  };

  return (
    <Accordion
      disableGutters
      expanded={expandedSection === title}
      onChange={handleChange}
      sx={{
        [`&.${accordionClasses.root}::before`]: {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          [`&.${accordionSummaryClasses.root}`]: {
            transition: "0.3s border-bottom",
            borderBottom: "0px solid transparent",
          },
          [`&.${accordionSummaryClasses.expanded}`]: {
            transition: "0.3s border-bottom",
            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "cobalt.lynch",
          }}
        >
          {open ? title : null}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          px: 0,
        }}
      >
        <List>
          {titlesAndRoutesButtons.map((element, key) => (
            <ListItemButton
              key={key}
              onClick={() => navigate(element.route)}
              sx={{
                fontSize: "2vh",
                fontWeight: 500,
                minWidth: `${widthItem}px`,
                color: "cobalt.ultramarine",
              }}
            >
              <ListItemIcon>{element.icon}</ListItemIcon>
              <ListItemText sx={{ ml: 1 }} primary={element.label} />
            </ListItemButton>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const ClosedMenuSection = (props: MenuSectionProps) => {
  const { titlesAndRoutesButtons, widthItem } = props;
  const navigate: NavigateFunction = useNavigate();

  return (
    <List>
      {titlesAndRoutesButtons.map((element, key) => (
        <ListItem
          button
          key={key}
          onClick={() => navigate(element.route)}
          sx={{
            textAlign: "center",
            fontSize: "2vh",
            fontWeight: 500,
            minWidth: `${widthItem}px`,
            color: "cobalt.ultramarine",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: "center",
            }}
          >
            {element.icon}
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

const MenuSection = (props: MenuSectionProps) => {
  return props.open ? (
    <OpenMenuSection {...props} />
  ) : (
    <ClosedMenuSection {...props} />
  );
};

export default MenuSection;
