import { Dialog } from "@docaposte-agility/da-design-system";
import React, { ChangeEvent, useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { Nas } from "@/types/webservices_ovh_db.types";
import SnackbarConfirm from "@/components/SnackbarConfirm";
import { GridData } from "@/components/GridData";
import { FieldColumn } from "@/types/others.types";
import { usePartitions, useUpdateNasName } from "@/services/OvhService";
import { useQueryClient } from "react-query";

interface ModalNasProps {
  openModal: boolean;
  handleCloseModal: () => void;
  nasData: Nas;
}

const ModalNas = (props: ModalNasProps) => {
  const { openModal, handleCloseModal, nasData } = props;
  const [nasName, setNasName] = useState<string>(nasData.customName);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { partitions, isLoading: isLoadingPartitions } = usePartitions(
    nasData.serviceName,
    nasData.ovhAccount,
  );

  const queryClient = useQueryClient();

  const { mutate: updateNasName } = useUpdateNasName({
    onSuccess: () => {
      queryClient.invalidateQueries("nas");
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setNasName(nasData.customName), [openModal]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (confirm: boolean = false) => {
    setOpenSnackbar(false);
    if (typeof confirm === "boolean" && confirm) changeNasName();
  };

  const changeNasName = () => {
    updateNasName({
      name: nasData.customName,
      newName: nasName,
      ovhAccount: nasData.ovhAccount,
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNasName(e.target.value);
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleCloseModal();
          handleCloseSnackbar();
        }}
        maxWidth="md"
      >
        <DialogTitle>
          <div>
            <Typography variant="h4">{`${nasData.customName} - ${nasData.ovhAccount}`}</Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="h4"
            sx={{ ml: "1vh", mb: "2vh", color: "cobalt.bleuDigital100" }}
          >
            Renommer le Nas
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <TextField
              type="text"
              label="Nom du Nas"
              name={nasData.customName}
              onChange={handleInputChange}
              value={nasName}
              sx={{ width: "220px", pt: "2px" }}
            />

            <Button
              color="primary"
              disabled={openSnackbar}
              onClick={() => {
                handleOpenSnackbar();
              }}
            >
              Renommer
            </Button>
          </Stack>
        </DialogContent>
        <DialogContent>
          <Typography
            variant="h4"
            sx={{
              mb: "2vh",
              color: "cobalt.bleuDigital100",
            }}
          >
            Partition
          </Typography>
          <GridData
            rows={partitions || []}
            columns={columnsNas}
            fixedSize={5}
            loading={isLoadingPartitions}
          />
        </DialogContent>
        <SnackbarConfirm
          openSnackbar={openSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          title="Renommer le Nas."
          text="Voulez-vous renommer ce Nas ?"
          top="50px"
        />
      </Dialog>
    </>
  );
};

const columnsNas: Array<FieldColumn> = [
  {
    field: "partitionName",
    headerName: "Nom",
    width: 260,
    headerAlign: "center",
  },
  {
    field: "size",
    headerName: "Taille en Go",
    width: 260,
    headerAlign: "center",
  },
  {
    field: "protocol",
    headerName: "Protocole",
    width: 260,
    headerAlign: "center",
  },
];

export default ModalNas;
