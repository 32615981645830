import { useNas } from "@/services/OvhService";
import { useEffect, useState } from "react";
import Filter from "@/components/Filter";
import { GridData } from "@/components/GridData";
import { Nas as NasType } from "@/types/webservices_ovh_db.types";
import { FieldColumn } from "@/types/others.types";
import { NasFilters, FiltersTypes } from "@/types/webservices_filters.types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Button, Container, Grid, IconButton, Typography } from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import ModalNas from "@/components/webservices-ovh/ModalNas";
import { styled } from "@mui/material/styles";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const TestStyle = styled(Typography)(() => ({
  borderRadius: "20px",
  padding: "3px",
  flex: "150px",
  height: "30px",
  margin: "10px",
}));

const Nas = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<NasType | undefined>();
  const [filteredNas, setFilteredNas] = useState<Array<NasType>>([]);

  const handleOpenModal = (row: NasType) => {
    setActiveRow(row);
    setOpenModal(true);
  };

  const columns = getColumns(handleOpenModal);

  const [filters, setFilters] = useState<NasFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as NasFilters;
  });

  const {
    nas,
    refetch: refetchNas,
    isLoading: isLoadingNas,
  } = useNas({
    onSuccess: (data) => {
      filter(data.data);
    },
  });

  useEffect(() => {
    filter(nas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, nas]);

  const handleCloseModal = async () => {
    setOpenModal(false);
    await refetchNas();
  };

  const filter = (nas: NasType[]) => {
    let filterResults: Array<NasType> = nas;
    if (filterResults) {
      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter((nas: NasType) =>
            nas[key].includes(filter),
          );
        }
      });
    }
    setFilteredNas(filterResults);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            Nas
          </Typography>
        </Grid>
        <Grid item sx={{ height: "24px" }}>
          <Button
            startIcon={<CobaltIcon name="refresh-cw" />}
            onClick={() => refetchNas()}
            variant="text"
            sx={{ height: "24px", pt: 0, pb: 0 }}
          >
            Recharger
          </Button>
        </Grid>
      </Grid>
      <Filter
        fields={columns}
        filters={filters}
        setFilters={setFilters as (filters: FiltersTypes) => void}
      />
      <GridData
        rows={filteredNas || []}
        columns={columns}
        loading={isLoadingNas}
      />
      {activeRow && (
        <ModalNas
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          nasData={activeRow}
        />
      )}
    </Container>
  );
};

const calculateSizeLeft = (
  zpoolSize: number,
  zpoolCapacity: number,
): number => {
  return zpoolSize - zpoolSize * zpoolCapacity;
};

const getColumns = (handleOpenModal): Array<FieldColumn> => [
  {
    field: "customName",
    headerName: "Nom",
    flex: 250,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "serviceName",
    headerName: "Service",
    flex: 250,
    filter: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "datacenter",
    headerName: "Datacenter",
    flex: 200,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "ip",
    headerName: "IP",
    flex: 200,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "ovhAccount",
    headerName: "Compte OVH",
    flex: 200,
    headerAlign: "center",
    align: "center",
    filter: true,
  },
  {
    field: "zpoolSize",
    headerName: "Taille",
    flex: 95,
    headerAlign: "center",
    align: "center",
    filter: true,
    renderCell: (params: GridRenderCellParams) => (
      <Typography
        sx={{
          color: "cobalt.lynch",
        }}
      >
        {`${params.row.zpoolSize} GB`}
      </Typography>
    ),
  },
  {
    field: "zpoolSizeLeft",
    headerName: "Capacité restante",
    flex: 170,
    headerAlign: "center",
    align: "center",
    filter: false,
    renderCell: (params: GridRenderCellParams) => {
      const sizeLeft: number = calculateSizeLeft(
        params.row.zpoolSize,
        params.row.zpoolCapacity,
      );
      return (
        <>
          {sizeLeft > 50 ? (
            <TestStyle
              sx={{
                color: "cobalt.white",
                backgroundColor: "cobalt.userBlue",
                fontWeight: "bold",
              }}
            >
              {sizeLeft} GB restant
            </TestStyle>
          ) : sizeLeft > 0 ? (
            <TestStyle
              sx={{
                color: "cobalt.white",
                backgroundColor: "cobalt.orangeText",
                fontWeight: "bold",
              }}
            >
              {sizeLeft} GB restant
            </TestStyle>
          ) : (
            <TestStyle
              sx={{
                color: "cobalt.white",
                backgroundColor: "text.primary",
                fontWeight: "bold",
              }}
            >
              Plein
            </TestStyle>
          )}
        </>
      );
    },
  },
  {
    field: "more",
    headerName: "",
    flex: 32,
    filter: false,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <IconButton
            aria-label="config button"
            component="span"
            sx={{ color: "cobalt.ultramarine" }}
            onClick={() => handleOpenModal(params.row)}
          >
            <MoreVertOutlined />
          </IconButton>
        </>
      );
    },
  },
];

export default Nas;
