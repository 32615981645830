import { FieldColumn } from "@/types/others.types";
import { FiltersTypes } from "@/types/webservices_filters.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";

const MIN_WITH_FOR_CROSS: number = 120;

interface FilterProps {
  fields: Array<FieldColumn>;
  filters: FiltersTypes;
  setFilters: (filters: FiltersTypes) => void;
}

const Filter = (props: FilterProps) => {
  const { fields, filters, setFilters } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const resetTextFields = () => {
    let flts: object = {};
    fields.forEach((field: FieldColumn) => {
      flts[field.field] = "";
    });
    setFilters(flts as FiltersTypes);
  };

  const resetTextField = (name: string) => {
    setFilters({
      ...filters,
      [name]: "",
    });
  };

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ mb: "1.5vh" }}
      >
        <Grid item sx={{ margin: "auto 0 auto 0" }}>
          <Typography variant="h4" color="cobalt.ultramarine">
            Filtres
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            size="small"
            sx={{
              color: "cobalt.userBlue",
            }}
            startIcon={<CobaltIcon name="delete" />}
            onClick={resetTextFields}
          >
            Effacer les filtres
          </Button>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          "& .MuiFormLabel-root": {
            color: "cobalt.ultramarine",
          },
        }}
      >
        {fields.map((field, key) =>
          field.filter ? (
            <TextField
              type="text"
              name={field.field}
              placeholder={field.headerName}
              onChange={handleInputChange}
              value={filters[field.field]}
              sx={{
                flex: `${field.flex}`,
              }}
              fullWidth
              InputProps={{
                endAdornment:
                  (field.flex || 100) >= MIN_WITH_FOR_CROSS ? (
                    <InputAdornment
                      position="end"
                      sx={{ color: "cobalt.grey20", cursor: "pointer" }}
                      onClick={() => resetTextField(field.field)}
                    >
                      <Close />
                    </InputAdornment>
                  ) : null,
              }}
            />
          ) : (
            <Box key={key} sx={{ flex: `${field.flex}` }} />
          ),
        )}
      </Stack>
    </Paper>
  );
};

export default Filter;
