import * as Yup from "yup";

export const toolValidationSchema = Yup.object().shape({
  name: Yup.string().required("Le nom est requis"),
  link: Yup.string()
    .url("Le lien n'est pas bien formé")
    .required("Le lien est requis"),
  logo: Yup.string()
    .url("Le lien n'est pas bien formé")
    .required("Le lien vers le logo est requis"),
  section: Yup.string().required("La section est requise"),
  description: Yup.string().required("La description est requise"),
  contact_label: Yup.string().required("Le label de contact est requis"),
  contact_link: Yup.string().required("Le lien de contact est requis"),
  manual_link: Yup.string()
    .url("Le lien n'est pas bien formé")
    .required("Le lien du manuel est requis"),
});
