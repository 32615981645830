import { useServers } from "@/services/OvhService";
import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Filter from "@/components/Filter";
import { GridData } from "@/components/GridData";
import { Server } from "@/types/webservices_ovh_db.types";
import { FieldColumn } from "@/types/others.types";
import { FiltersTypes, ServerFilters } from "@/types/webservices_filters.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const Servers = () => {
  const [filteredServers, setFilteredServers] = useState<Array<Server>>([]);
  const [filters, setFilters] = useState<ServerFilters>(() => {
    return columns.reduce(
      (acc, { field }) => ({ ...acc, [field]: "" }),
      {},
    ) as ServerFilters;
  });

  const {
    servers,
    refetch: refetchServers,
    isLoading: isLoadingServers,
  } = useServers({
    onSuccess: (data) => {
      filter(data.data);
    },
  });

  useEffect(() => {
    filter(servers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filter = (servers: Server[]) => {
    let filterResults: Array<Server> = servers;
    if (filterResults) {
      Object.entries(filters).forEach(([key, filter]: Array<any>) => {
        if (filter) {
          filterResults = filterResults.filter(
            (serv: Server) => serv[key] && `${serv[key]}`.includes(filter),
          );
        }
      });
    }
    setFilteredServers(filterResults);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            Serveurs
          </Typography>
        </Grid>
        <Grid item sx={{ height: "24px" }}>
          <Button
            startIcon={<CobaltIcon name="refresh-cw" />}
            onClick={() => refetchServers()}
            variant="text"
            sx={{ height: "24px", pt: 0, pb: 0 }}
          >
            Recharger
          </Button>
        </Grid>
      </Grid>
      <Filter
        fields={columns}
        filters={filters}
        setFilters={setFilters as (filters: FiltersTypes) => void}
      />
      <GridData
        rows={filteredServers || []}
        columns={columns}
        loading={isLoadingServers}
      />
    </Container>
  );
};

const columns: Array<FieldColumn> = [
  {
    field: "name",
    headerName: "Nom",
    flex: 310,
    align: "center",
    headerAlign: "center",
    filter: true,
  },
  {
    field: "reverse",
    headerName: "Reverse",
    flex: 300,
    filter: true,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "datacenter",
    headerName: "Datacenter",
    flex: 150,
    align: "center",
    headerAlign: "center",
    filter: true,
  },
  {
    field: "ovhAccount",
    headerName: "Compte OVH",
    flex: 170,
    align: "center",
    headerAlign: "center",
    filter: true,
  },
  {
    field: "rack",
    headerName: "Rack",
    flex: 140,
    align: "center",
    headerAlign: "center",
    filter: true,
  },
  {
    field: "os",
    headerName: "OS",
    flex: 170,
    align: "center",
    headerAlign: "center",
    filter: true,
  },
  {
    field: "linkSpeed",
    headerName: "Vitesse (Mo)",
    flex: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    filter: true,
  },
];

export default Servers;
