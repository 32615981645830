import ButtonWithConfirm from "@/components/ButtonWithConfirm";
import DateInput from "@/components/DateInput";
import MyAlert from "@/components/MyAlert";
import Textfield from "@/components/Textfield";
import { levelToColor, mapLevelToFrench } from "@/mappers/incidents.mappers";
import {
  useCreateIncident,
  useUpdateIncident,
} from "@/services/IncidentsService";
import {
  CreateIncident,
  Incident,
  Level,
  UpdateIncident,
} from "@/types/incidents.types";
import { Alert, Severity } from "@/types/others.types";
import { HOURS_OPTIONS } from "@/utils/hours.utils";
import { incidentValidationSchema } from "@/validations/incident.schema";
import { Dialog } from "@docaposte-agility/da-design-system";
import {
  Autocomplete,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import Select from "../Select";
import SelectLevelBadge from "./SelectLevelBadge";

interface ModalIncidentProps {
  values?: Incident;
  open: boolean;
  handleClose: () => void;
  scheduledIncident: boolean;
}

const DEFAULT_VALUES_ALERT = {
  open: false,
  severity: "error" as Severity,
  text: "",
};

const ModalIncident = (props: ModalIncidentProps) => {
  const { values, open, handleClose, scheduledIncident } = props;
  const [alert, setAlert] = useState<Alert>(DEFAULT_VALUES_ALERT);
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [displayErrorTime, setDisplayErrorTime] = useState<boolean>(false);

  const dateObject: Date | undefined = useMemo(
    () => (values?.date ? new Date(values?.date) : undefined),
    [values?.date],
  );

  useEffect(() => {
    if (dateObject) {
      setTime(dayjs(dateObject).format("HH:mm"));
    }
  }, [dateObject]);

  const queryClient = useQueryClient();

  const initialValues = {
    title: values?.title || "",
    level: values?.level || "Unknown",
    type: values?.type || "",
    author: values?.author || "",
    resolutionTime: values?.resolutionTime || "",
    impactedEnv: values?.impactedEnv || "",
    description: values?.description || "",
    link: values?.link || "",
    date: values?.date,
  };

  const getAttributesToUpdate = (): UpdateIncident => {
    let formValues: UpdateIncident = {
      ...formik.values,
      date: scheduledIncident ? newDateObject : undefined,
      link:
        !formik.values.link && !values?.link ? undefined : formik.values.link,
      description:
        !formik.values.description && !values?.description
          ? undefined
          : formik.values.description,
    };
    let attributesToUpdate: UpdateIncident =
      removeNotModifiedValues(formValues);
    return attributesToUpdate;
  };

  const removeNotModifiedValues = (formValues: UpdateIncident) => {
    return Object.entries(formValues)
      .filter(
        (attr) =>
          attr[1] !== undefined && values && attr[1] !== values[attr[0]],
      )
      .reduce((acc, attr) => ({ ...acc, [attr[0]]: attr[1] }), {});
  };

  const processChanges = async () => {
    if (createMode) {
      const copy: CreateIncident = formik.values;
      Object.entries(copy).filter(([k, v]) => (v === "" ? delete copy[k] : ""));
      if (scheduledIncident && newDateObject) copy.date = newDateObject;
      await createIncident({
        values: copy,
      });
    } else {
      const updatedAttributes = getAttributesToUpdate();
      await updateIncident({
        id: values?.id || "",
        values: updatedAttributes,
      });
    }
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: incidentValidationSchema,
    validate: () => {
      if (scheduledIncident === true) {
        setDisplayErrorTime(!time ? true : false);
      }
    },
    onSubmit: () => processChanges(),
  });

  const newDateObject: Date | undefined = useMemo(() => {
    const timeSplited: string[] = time.split(":");
    const newDate = new Date(dayjs(formik.values.date).format("YYYY-MM-DD"));
    newDate.setHours(parseInt(timeSplited[0]));
    newDate.setMinutes(parseInt(timeSplited[1]));
    return formik.values.date && time ? newDate : undefined;
  }, [formik, time]);
  const { mutate: updateIncident } = useUpdateIncident({
    onSuccess: () => {
      queryClient.invalidateQueries("incidents");
      queryClient.invalidateQueries("incidentsStats");
      queryClient.invalidateQueries("incidentsStatsGroupByDay");
      setAlert({
        open: true,
        severity: "success",
        text: "Les données ont bien été modifiées !",
      });
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur lors de la modification des données !",
      });
    },
  });

  const { mutate: createIncident } = useCreateIncident({
    onSuccess: () => {
      queryClient.invalidateQueries("incidents");
      queryClient.invalidateQueries("incidentsStats");
      queryClient.invalidateQueries("incidentsStatsGroupByDay");
      formik.resetForm();
      setTime("");
      setDisplayErrorTime(false);
      handleClose();
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur lors de la création des données !",
      });
    },
  });

  const createMode: boolean =
    !values && scheduledIncident !== undefined ? true : false;

  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm();
        if (dateObject) {
          const minutes = dateObject.getMinutes();
          setTime(
            `${dateObject.getHours()}:${
              minutes < 10 ? "0" + minutes : minutes
            }`,
          );
        }
        setDisplayErrorTime(false);
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
    >
      <DialogTitle sx={{ minWidth: "60vw" }}>
        <div>
          <Typography variant="h4">
            {createMode
              ? "Création d'un incident"
              : "Modification de l'incident"}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Textfield
                name="title"
                label="Titre"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ marginBottom: "1vh" }}>Criticité</InputLabel>

              <Select
                name="level"
                formik={formik}
                valuesPossible={[
                  "Critical",
                  "High",
                  "Medium",
                  "Low",
                  "Unknown",
                ]}
                value={formik.values.level}
                onChange={formik.handleChange}
                sx={{ width: "100%" }}
                disabled={fieldDisabled}
                renderValue={(value) => (
                  <SelectLevelBadge
                    colorOverride={levelToColor(value as Level)}
                  >
                    {mapLevelToFrench(value as Level)}
                  </SelectLevelBadge>
                )}
                renderOption={(value) => (
                  <SelectLevelBadge
                    colorOverride={levelToColor(value as Level)}
                  >
                    {mapLevelToFrench(value as Level)}
                  </SelectLevelBadge>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Textfield
                name="type"
                label="Type"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Textfield
                name="author"
                label="Auteur"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Textfield
                name="resolutionTime"
                label="Temps de résolution"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Textfield
                name="impactedEnv"
                label="Environnements impactés"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            {scheduledIncident && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" sx={{ mb: "8px" }}>
                  Date
                </Typography>
                <Stack direction="row" alignItems="center">
                  <DateInput formik={formik} name="date" />
                  <Typography variant="body1" sx={{ m: "0 8px" }}>
                    à
                  </Typography>
                  <Autocomplete
                    disablePortal
                    options={HOURS_OPTIONS}
                    sx={{
                      width: 300,
                      "&	.MuiAutocomplete-input": {
                        height: "7px",
                      },
                    }}
                    value={time || null}
                    disabled={fieldDisabled}
                    onChange={(event, value) => {
                      if (value) setTime(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    ListboxProps={{ style: { maxHeight: "200px" } }}
                  />
                </Stack>
                {displayErrorTime && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "cobalt.redText",
                      fontStyle: "italic",
                      fontSize: "0.75rem",
                      mt: "3px",
                    }}
                  >
                    L'heure de l'incident doit être renseignée !
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Textfield
                name="link"
                label="Lien"
                disabled={fieldDisabled}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Textfield
                name="description"
                label="Description"
                disabled={fieldDisabled}
                formik={formik}
                maxRows={6}
                multiline
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ButtonWithConfirm
                color="primary"
                confirmText={`Voulez-vous vraiment ${
                  createMode ? "créer" : "modifier"
                } cet incident ?`}
                disabled={fieldDisabled}
                onUpdateState={setFieldDisabled}
                type="submit"
              >
                {createMode ? "Créer" : "Modifier"}
              </ButtonWithConfirm>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <MyAlert alert={alert} setAlert={setAlert} top="300px" />
    </Dialog>
  );
};

export default ModalIncident;
