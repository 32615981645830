import axios, { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { NewValuesObject, ObjectUpdateSubDomain } from "@/types/others.types";
import {
  Application,
  Credential,
  Dns,
  Ip,
  Nas,
  Partition,
  Record,
  Reverse,
  Server,
  Task,
} from "@/types/webservices_ovh_db.types";

const endpoint: string | undefined = window.__ENV__.REACT_APP_ENDPOINT_BACK;

export const useServers = (
  config: UseQueryOptions<
    AxiosResponse<Server[]>,
    unknown,
    AxiosResponse<Server[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["servers"],
    () => axios.get(`${endpoint}/servers`),
    { ...config },
  );
  const servers = data?.data || [];
  return { servers, refetch, ...rest };
};

export const useIps = (
  config: UseQueryOptions<
    AxiosResponse<Ip[]>,
    unknown,
    AxiosResponse<Ip[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["ips"],
    () => axios.get(`${endpoint}/ips`),
    { ...config },
  );
  const ips = data?.data || [];
  return { ips, refetch, ...rest };
};

export const useUpdateIp = (
  config: UseMutationOptions<
    void,
    unknown,
    { ip: string; values: NewValuesObject }
  > = {},
) => {
  return useMutation(
    ({ ip, values }) => {
      return axios.patch(`${endpoint}/ips/${ip}`, values);
    },
    { ...config },
  );
};

export const useReverses = (
  ip: string,
  config: UseQueryOptions<
    AxiosResponse<Reverse[]>,
    unknown,
    AxiosResponse<Reverse[]>,
    Array<{ ipEncoded: string } | string>
  > = {},
) => {
  const ipEncoded = encodeURIComponent(ip);
  const { data, refetch, ...rest } = useQuery(
    ["reverses", { ipEncoded }],
    () => {
      return axios.get(`${endpoint}/ips/${ipEncoded}/reverse`);
    },
    { ...config },
  );
  const reverses = data?.data || [];
  return { reverses, refetch, ...rest };
};

export const useTasks = (
  ip: string,
  config: UseQueryOptions<
    AxiosResponse<Task[]>,
    unknown,
    AxiosResponse<Task[]>,
    Array<{ ipEncoded: string } | string>
  > = {},
) => {
  const ipEncoded = encodeURIComponent(ip);
  const { data, refetch, ...rest } = useQuery(
    ["tasks", { ipEncoded }],
    () => {
      return axios.get(`${endpoint}/ips/${ipEncoded}/task`);
    },
    { ...config },
  );
  const tasks = data?.data || [];
  return { tasks, refetch, ...rest };
};

export const useUpdateReverse = (
  config: UseMutationOptions<
    void,
    unknown,
    {
      ip: string;
      values: { ipReverse: string; reverse: string; ovhAccount: string };
    }
  > = {},
) => {
  return useMutation(
    ({ ip, values }) => {
      return axios.patch(`${endpoint}/ips/${ip}/reverse`, values);
    },
    { ...config },
  );
};

export const useApplications = (
  config: UseQueryOptions<
    AxiosResponse<Application[]>,
    unknown,
    AxiosResponse<Application[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["applications"],
    () => axios.get(`${endpoint}/applications`),
    { ...config },
  );
  const applications: Application[] = data?.data || [];
  return { applications, refetch, ...rest };
};

export const useDns = (
  config: UseQueryOptions<
    AxiosResponse<Dns[]>,
    unknown,
    AxiosResponse<Dns[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["dns"],
    () => axios.get(`${endpoint}/dns`),
    { ...config },
  );
  const dns: Dns[] = data?.data || [];
  return { dns, refetch, ...rest };
};

export const useNas = (
  config: UseQueryOptions<
    AxiosResponse<Nas[]>,
    unknown,
    AxiosResponse<Nas[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["nas"],
    () => axios.get(`${endpoint}/nas`),
    { ...config },
  );
  const nas: Nas[] = data?.data || [];
  return { nas, refetch, ...rest };
};

export const usePartitions = (
  name: string,
  ovhAccount: string,
  config: UseQueryOptions<
    AxiosResponse<Partition[]>,
    unknown,
    AxiosResponse<Partition[]>,
    Array<{ name: string; ovhAccount: string } | string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["partitions", { name, ovhAccount }],
    () => {
      return axios.get(`${endpoint}/nas/${name}/partition`, {
        params: {
          ovhAccount: ovhAccount,
        },
      });
    },
    { ...config },
  );
  const partitions: Partition[] = data?.data || [];
  return { partitions, refetch, ...rest };
};

export const useUpdateNasName = (
  config: UseMutationOptions<
    void,
    unknown,
    { name: string; newName: string; ovhAccount: string }
  > = {},
) => {
  return useMutation(
    ({ name, newName, ovhAccount }) => {
      return axios.patch(
        `${endpoint}/nas/${name}`,
        {
          customName: newName,
        },
        {
          params: { ovhAccount: ovhAccount },
        },
      );
    },
    { ...config },
  );
};

export const useCredentials = (
  config: UseQueryOptions<
    AxiosResponse<Credential[]>,
    unknown,
    AxiosResponse<Credential[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["credentials"],
    () => axios.get(`${endpoint}/credentials`),
    { ...config },
  );
  const credentials: Credential[] = data?.data || [];
  return { credentials, refetch, ...rest };
};

export const useSubDomains = (
  zone: string,
  ovhAccount: string,
  config: UseQueryOptions<
    AxiosResponse<Record[]>,
    unknown,
    AxiosResponse<Record[]>,
    Array<{ zone: string; ovhAccount: string } | string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["subDomains", { zone, ovhAccount }],
    () =>
      axios.get(`${endpoint}/dns/${zone}/records`, {
        params: {
          ovhAccount: ovhAccount,
        },
      }),
    { ...config },
  );
  const subDomains: Record[] = data?.data || [];
  return { subDomains, refetch, ...rest };
};

export const useDeleteSubDomain = (
  config: UseMutationOptions<
    void,
    unknown,
    { zone: string; idRecord: number; ovhAccount: string }
  > = {},
) => {
  return useMutation(
    ({ zone, idRecord, ovhAccount }) => {
      return axios.delete(`${endpoint}/dns/${zone}/records/${idRecord}`, {
        params: { ovhAccount: ovhAccount },
      });
    },
    { ...config },
  );
};

export const useAddSubDomain = (
  config: UseMutationOptions<
    void,
    unknown,
    { zone: string; record: Record; ovhAccount: string }
  > = {},
) => {
  return useMutation(
    ({ zone, record, ovhAccount }) => {
      return axios.post(`${endpoint}/dns/${zone}/records`, record, {
        params: { ovhAccount: ovhAccount },
      });
    },
    { ...config },
  );
};

export const useUpdateSubDomain = (
  config: UseMutationOptions<
    void,
    unknown,
    {
      idRecord: number;
      values: ObjectUpdateSubDomain;
      zone: string;
      ovhAccount: string;
    }
  > = {},
) => {
  return useMutation(
    ({ idRecord, values, zone, ovhAccount }) => {
      return axios.put(`${endpoint}/dns/${zone}/records/${idRecord}`, values, {
        params: { ovhAccount: ovhAccount },
      });
    },
    { ...config },
  );
};
