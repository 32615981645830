import LabelWithTooltip from "@/components/LabelWithTooltip";
import Textfield from "@/components/Textfield";
import { useProjects } from "@/services/ProjectsService";
import { Project } from "@/types/projects.types";
import {
  BugReportTicket,
  TicketDef,
  TicketFormData,
} from "@/types/tickets.types";
import { bugReportValidationSchema } from "@/validations/tickets/bugReport.schema";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { useMemo } from "react";

interface BugReportTicketFormProps {
  formik: FormikProps<TicketFormData<BugReportTicket>>;
}

const getBugReportDefaultValues = (): BugReportTicket => ({
  project: "",
  projectId: "",
  priority: "moyenne",
  description: "",
  stepsToReproduce: [""],
  expectedResults: "",
  actualResults: "",
  context: "",
  environment: "",
  possibleFix: "",
});

const serializeBugReportTicket = (data: TicketFormData<BugReportTicket>) => ({
  title: data.title,
  content: {
    ...data.content,
    stepsToReproduce: data.content.stepsToReproduce.filter(
      (step) => step !== "",
    ),
  },
});

export const bugReportTicketDef: TicketDef<BugReportTicket> = {
  label: "Rapport de bug",
  validationSchema: bugReportValidationSchema,
  getComponent: (formik: FormikProps<TicketFormData<BugReportTicket>>) => (
    <BugReportTicketForm formik={formik} />
  ),
  defaultValues: getBugReportDefaultValues,
  serializeData: serializeBugReportTicket,
};

const BugReportTicketForm = ({ formik }: BugReportTicketFormProps) => {
  const { projects } = useProjects();

  const projectMap = useMemo(() => {
    return projects.reduce((acc, project: Project) => {
      acc[project.id!] = { id: project.id!, name: project.name };
      return acc;
    }, {});
  }, [projects]);

  const steps = formik.values.content.stepsToReproduce;

  const handleRemoveStep = (index: number) => {
    steps.splice(index, 1);
    formik.setFieldValue("content.stepsToReproduce", steps);
  };

  const handleAddStep = () => {
    steps.push("");
    formik.setFieldValue("content.stepsToReproduce", steps);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Details
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          disablePortal
          options={Object.keys(projectMap)}
          getOptionLabel={(option) => (option ? projectMap[option].name : "")}
          value={formik.values.content.projectId}
          onChange={(_, val) => {
            formik.setFieldValue("content.projectId", val);
            formik.setFieldValue(
              "content.project",
              val ? projectMap[val].name : "",
            );
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
              "& .MuiAutocomplete-input": { p: "12px 16px" },
            },
          }}
          renderInput={(params) => (
            <Textfield
              {...params}
              formik={formik}
              name="content.project"
              label="Projet associé"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="bug-report-select-priority" sx={{ mb: 1 }}>
          Priorité
        </InputLabel>
        <Select
          fullWidth
          labelId="bug-report-select-priority"
          value={formik.values.content.priority}
          onChange={formik.handleChange}
          name="content.priority"
        >
          <MenuItem value="basse">Basse</MenuItem>
          <MenuItem value="moyenne">Moyenne</MenuItem>
          <MenuItem value="haute">Haute</MenuItem>
        </Select>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label={
            <LabelWithTooltip
              label="Description"
              tooltipTitle="Fournir une description plus détaillée de l'issue en elle même, et pourquoi tu considères que c'est un bug ?"
            />
          }
          name="content.description"
          formik={formik}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.userBlue">
          Comportement
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label={
            <LabelWithTooltip
              label="Comportement attendu"
              tooltipTitle="Qu'est-ce qui devrait se passer ?"
            />
          }
          name="content.expectedResults"
          formik={formik}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label={
            <LabelWithTooltip
              label="Comportement actuel"
              tooltipTitle="Qu'est-ce qui se passe actuellement ?"
            />
          }
          name="content.actualResults"
          formik={formik}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label={
            <LabelWithTooltip
              label="Contexte"
              tooltipTitle="Comment ce bug vous a-t-il affecté ? Qu'essayiez-vous d'accomplir ?"
            />
          }
          name="content.context"
          formik={formik}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.userBlue">
          Étapes à reproduire
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={2}>
          {steps.slice(0, steps.length - 1).map((_, index) => (
            <Grid container key={index}>
              <Grid item xs={11}>
                <Textfield
                  key={index}
                  label={`Étape ${index + 1}`}
                  name={`content.stepsToReproduce[${index}]`}
                  formik={formik}
                />
              </Grid>
              <Grid
                item
                xs={1}
                display="flex"
                alignItems="end"
                justifyContent="center"
              >
                <IconButton onClick={() => handleRemoveStep(index)}>
                  <CobaltIcon
                    name="trash-2"
                    fontSize={24}
                    color="cobalt.redIcon"
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid container>
            <Grid item xs={11}>
              <Textfield
                label={`Étape ${steps.length}`}
                name={`content.stepsToReproduce[${steps.length - 1}]`}
                error={
                  typeof formik.errors.content?.stepsToReproduce === "string" &&
                  Boolean(formik.errors.content?.stepsToReproduce)
                }
                helperText={
                  typeof formik.errors.content?.stepsToReproduce === "string" &&
                  formik.errors.content?.stepsToReproduce
                }
                formik={formik}
              />
            </Grid>
            <Grid
              item
              xs={1}
              display="flex"
              alignItems="end"
              justifyContent="center"
            >
              <IconButton onClick={handleAddStep}>
                <CobaltIcon
                  color="cobalt.bleuDigital100"
                  fontSize={24}
                  name="plus-circle"
                />
              </IconButton>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Textfield
          label={
            <LabelWithTooltip
              label="Environnement"
              tooltipTitle="Incluez autant de détails pertinents que possible sur l'environnement dans lequel vous avez rencontré le bug."
            />
          }
          name="content.environment"
          formik={formik}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default BugReportTicketForm;
