import { RolesEnum } from "@/types/others.types";
import AuthService from "@/services/AuthService";
import ForbidenPage from "./ForbidenPage";

const PrivateRoute = ({ children, authorizedRole }) => {
  if (authorizedRole !== RolesEnum.All) {
    if (AuthService.hasRole(authorizedRole)) return children;
    else return <ForbidenPage />;
  } else return children;
};

export default PrivateRoute;
