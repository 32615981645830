import { Container } from "@mui/material";
import { useState } from "react";
import VerticalMenu from "./VerticalMenu";

const DRAWER_WIDTH: number = 270;

const WithMenu = ({ children }) => {
  const [drawerWidth, setDrawerWidth] = useState<number>(DRAWER_WIDTH);
  return (
    <>
      <VerticalMenu
        defaultDrawerWidth={DRAWER_WIDTH}
        actualDrawerWidth={drawerWidth}
        setActualDrawerWidth={setDrawerWidth}
      />
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mr: 0,
          backgroundColor: "cobalt.light",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default WithMenu;
