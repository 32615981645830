import { Box, Button, Stack, Typography } from "@mui/material";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { ImageType, ProjectFormData } from "@/types/projects.types";
import { getBase64StringImage } from "@/utils/image.utils";

interface ProjectDetailsFormStepProps {
  formik: FormikProps<ProjectFormData>;
}

const ProjectDetailsFormStep = (props: ProjectDetailsFormStepProps) => {
  const { formik } = props;
  const { image } = formik.values;

  const imageB64 = useMemo(() => {
    if (image === undefined) {
      return undefined;
    }

    if (image.type === "Buffer") {
      return {
        data: getBase64StringImage(image),
        type: "base64",
      } as ImageType;
    }

    return image;
  }, [image]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const selectedImage = files[0];
      formik.setFieldValue("imageName", selectedImage.name);

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onload = () => {
        let [type, data] = (reader.result as string).split(",");
        formik.setFieldValue("image", { type, data });
        formik.setFieldValue("isImageModified", true);
      };
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5">Détails du projet</Typography>
      <Textfield formik={formik} name="name" label="Nom du projet" />
      <Textfield
        formik={formik}
        name="description"
        label="Description du projet"
        multiline
        rows={4}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        direction="row"
        mt="100px"
      >
        <Button
          variant="outlined"
          color="primary"
          component="label"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          Sélectionner une image
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleImageChange}
          />
        </Button>

        {imageB64 ? (
          <Box
            component="img"
            src={`data:image/png;base64,${imageB64.data}`}
            alt="Illustration projet"
            sx={{ width: "50%" }}
          />
        ) : (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Aucune image sélectionnée
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ProjectDetailsFormStep;
