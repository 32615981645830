import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import { useState } from "react";
import { NewComerTicket, TicketFormData } from "@/types/tickets.types";
import { gitlabProjectValidationSchema } from "@/validations/tickets/newComer.schema";

interface GitlabRightFormProps {
  formik: FormikProps<TicketFormData<NewComerTicket>>;
  attributeFormikPrefix: string;
}

interface GitlabProject {
  url: string;
  role: string | null;
}

export const getGitlabDefaultRightValues = () => ({
  external: false,
  projects: [],
});

export const getGitlabPlateformRightsDef = (prefix: string) => ({
  getDefaultRightValues: getGitlabDefaultRightValues,
  getRequiredRightsComponent: (
    formik: FormikProps<TicketFormData<NewComerTicket>>,
  ) => (
    <GitlabRequiredRightsForm formik={formik} attributeFormikPrefix={prefix} />
  ),
});

const GitlabRequiredRightsForm = ({
  formik,
  attributeFormikPrefix,
}: GitlabRightFormProps) => {
  const [project, setProject] = useState<GitlabProject>({
    url: "",
    role: "Developer",
  });
  const formValues = getIn(formik.values, attributeFormikPrefix);

  const handleAddProject = () => {
    if (!gitlabProjectValidationSchema.isValidSync(project)) {
      return;
    }
    formik.setFieldValue(`${attributeFormikPrefix}.projects`, [
      ...formValues.projects,
      project,
    ]);
    setProjectUrl("");
  };

  const setProjectRole = (role: string | null) => {
    setProject({ url: project.url, role });
  };

  const setProjectUrl = (url: string) => {
    setProject({ url, role: project.role });
  };

  const handleRemoveProject = (i: number) => {
    formValues.projects.splice(i, 1);
    formik.setFieldValue(
      `${attributeFormikPrefix}.projects`,
      formValues.projects,
    );
  };

  const projects = getIn(formik.values, attributeFormikPrefix).projects;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.dodgerBlueIcon">
          Gitlab
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label="External ?"
          control={
            <Checkbox
              name={`${attributeFormikPrefix}.external`}
              onChange={formik.handleChange}
              checked={formValues.external}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Projets</Typography>
        {projects.length === 0 && (
          <List dense sx={{ listStyleType: "disc", py: 0 }}>
            {projects.map((project: any, i: number) => (
              <ListItemText
                key={i}
                sx={{ display: "list-item", ml: 4 }}
                primary={
                  <Typography variant="body1">
                    {project.url} ({project.role})
                    <IconButton onClick={() => handleRemoveProject(i)}>
                      <CobaltIcon name="trash-2" />
                    </IconButton>
                  </Typography>
                }
              />
            ))}
          </List>
        )}
      </Grid>
      <Grid item xs={5}>
        <TextField
          fullWidth
          label="Project url"
          onChange={(e) => setProjectUrl(e.target.value)}
          value={project.url}
        />
      </Grid>
      <Grid item xs={5}>
        <InputLabel id="gitlab-select-project-role" sx={{ mb: 1 }}>
          Role
        </InputLabel>
        <Select
          fullWidth
          labelId="gitlab-select-project-role"
          defaultValue={"Developer"}
          value={project.role}
          onChange={(e) => setProjectRole(e.target.value)}
        >
          <MenuItem value="Reporter">Reporter</MenuItem>
          <MenuItem value="Developer">Developer</MenuItem>
          <MenuItem value="Maintainer">Maintainer</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={handleAddProject}>
          <CobaltIcon
            color="cobalt.bleuDigital100"
            fontSize={24}
            name="plus-circle"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GitlabRequiredRightsForm;
