import { Tool } from "@/types/tools.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomCard from "../CustomCard";
import SearchResult from "./SearchResult";

interface CardToolProps {
  tool: Tool;
  search: string;
  openModal: () => void;
}

const CardTool = ({ tool, search, openModal }: CardToolProps) => {
  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal();
  };

  return (
    <CustomCard onClick={() => window.open(tool.link, "_blank")}>
      <Grid container alignItems="center">
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src={tool.logo}
            alt="Illustration projet"
            m={1}
            sx={{ height: "100%", maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={9}>
          <Stack sx={{ p: 0 }}>
            <Typography
              variant="h2"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <SearchResult base={tool.name} search={search} />
              <IconButton onClick={handleOpenModal} sx={{ pr: 0 }}>
                <CobaltIcon
                  name="info"
                  fontSize={20}
                  color="cobalt.ultramarine"
                  sx={{ textDecoration: "none" }}
                />
              </IconButton>
            </Typography>
            <Link
              to={tool.contact_link}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              style={{
                display: "block",
                marginLeft: "auto",
                textAlign: "right",
              }}
            >
              Contacter le support
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default CardTool;
