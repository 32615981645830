import { Card, CardProps } from "@mui/material";

const CustomCard = (props: CardProps) => (
  <Card
    {...props}
    sx={{
      ...props.sx,
      p: 2,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "cobalt.bleuDigital10",
      },
      height: "100px",
      display: "flex",
      alignItems: "center",
    }}
  />
);
export default CustomCard;
