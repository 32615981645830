import * as Yup from "yup";

export const bitwardenValidationSchema = Yup.object().shape({
  groups: Yup.array().transform((value) => {
    value.pop();
    return value;
  }),
});

export const gitlabProjectValidationSchema = Yup.object().shape({
  url: Yup.string()
    .url("format invalide, doit être de la forme https://exemple.com/...")
    .required("L'url du projet est requise"),
  role: Yup.string().required("Le rôle est requis"),
});

export const gitlabValidationSchema = Yup.object().shape({
  external: Yup.boolean(),
  projects: Yup.array().of(gitlabProjectValidationSchema),
});

export const keycloakValidationSchema = Yup.object().shape({
  environment: Yup.string().required("L'environnement est requis"),
  realm: Yup.string().required("Le royaume est requis"),
});

export const redmineValidationSchema = Yup.object().shape({
  project: Yup.string().required("Le projet est requis"),
});

export const teleportValidationSchema = Yup.object().shape({
  machine: Yup.string().required("La machine est requise"),
});

export const newComerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom est requis"),
  email: Yup.string().email().required("L'e-mail est requis"),
  arrivalDate: Yup.date().required("La date d'arrivée est requise"),
  addMaterialRequest: Yup.boolean(),
  platforms: Yup.array().of(Yup.string()),
  moreInfo: Yup.string(),
  materials: Yup.array()
    .transform((value, _) => {
      value.pop();
      return value;
    })
    .when("addMaterialRequest", {
      is: true,
      then: (schema) => schema.min(1, "Veuillez ajouter au moins un matériel"),
    })
    .of(
      Yup.object().shape({
        name: Yup.string().required("Le nom est requis"),
        description: Yup.string(),
      }),
    ),
  bitwarden: Yup.object().when("platforms", ([platforms], schema) =>
    platforms.includes("bitwarden")
      ? schema.concat(bitwardenValidationSchema)
      : schema,
  ),
  gitlab: Yup.object().when("platforms", ([platforms], schema) =>
    platforms.includes("gitlab")
      ? schema.concat(gitlabValidationSchema)
      : schema,
  ),
  keycloak: Yup.object().when("platforms", ([platforms], schema) =>
    platforms.includes("keycloak")
      ? schema.concat(keycloakValidationSchema)
      : schema,
  ),
  redmine: Yup.object().when("platforms", ([platforms], schema) =>
    platforms.includes("redmine")
      ? schema.concat(redmineValidationSchema)
      : schema,
  ),
  teleport: Yup.object().when("platforms", ([platforms], schema) =>
    platforms.includes("teleport")
      ? schema.concat(teleportValidationSchema)
      : schema,
  ),
});
