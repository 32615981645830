import * as Yup from "yup";

export const materialReturnValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom est requis"),
  encounteredIssue: Yup.string().required("Le problème rencontré est requis"),
  sinceWhen: Yup.date().required("La date d'arrivée est requise"),
  diagnostic: Yup.string().required("Le diagnostic est requise"),
  actionsBeforeDiagnostic: Yup.string().required(
    "Les manipulations avant le diagnostic sont requises",
  ),
});
