import axios from "axios";
import { UseMutationOptions, useMutation } from "react-query";

const endpoint: string | undefined = window.__ENV__.REACT_APP_ENDPOINT_BACK;

export const useCreateTicket = (
  config: UseMutationOptions<void, unknown, { type: string; values: any }> = {},
) => {
  return useMutation(
    ({ type, values }) => {
      return axios.post(`${endpoint}/tickets`, { type, ...values });
    },
    { ...config },
  );
};
