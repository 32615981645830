import MyAlert from "@/components/MyAlert";
import Select from "@/components/Select";
import Textfield from "@/components/Textfield";
import { useServers, useUpdateIp } from "@/services/OvhService";
import { Alert, NewValuesObject, ValuesEditIp } from "@/types/others.types";
import { Ip, Server } from "@/types/webservices_ovh_db.types";
import { ipValidationSchema } from "@/validations/webservices-ovh/ip.schema";
import { Dialog } from "@docaposte-agility/da-design-system";
import { Build } from "@mui/icons-material";
import {
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import ButtonWithConfirm from "../ButtonWithConfirm";

interface ModalEditIpProps {
  openModal: boolean;
  handleCloseModal: () => void;
  ipData: Ip;
}

const ModalEditIp = (props: ModalEditIpProps) => {
  const { openModal, handleCloseModal, ipData } = props;

  const [fieldsDisabled, setFieldDisabled] = useState<boolean>(false);
  const [values, setValues] = useState<ValuesEditIp | undefined>();
  const [alert, setAlert] = useState<Alert>();
  const queryClient = useQueryClient();

  useEffect(() => {
    const initValues: ValuesEditIp = {
      isRunning: ipData.isRunning,
      service: "",
      hostname: ipData.hostname,
      domain: ipData.domain,
      vmId: ipData.vmId,
      machineType: ipData.machineType,
      description: ipData.description,
    };
    setValues(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const { mutate: updateIp } = useUpdateIp({
    onSuccess: () => {
      queryClient.invalidateQueries("ips");
      setAlert({
        open: true,
        severity: "success",
        text: "Les modifications ont été effectuées",
      });
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur : la modification n'a pas été effectuée",
      });
    },
  });

  const updateValues = (values: ValuesEditIp, fields: Array<string>) => {
    if (values) {
      const newValues = getNewValues(values, fields);
      updateIp({
        ip: encodeURIComponent(ipData.ip),
        values: newValues,
      });
      setValues(values);
    }
  };

  const getNewValues = (
    values: ValuesEditIp,
    fieldsToUpdate: Array<string>,
  ): NewValuesObject => {
    if (values) {
      let newValues: NewValuesObject = {};
      fieldsToUpdate.forEach(
        (valueKey) => (newValues[valueKey] = values[valueKey]),
      );
      newValues.ovhAccount = ipData.ovhAccount;
      if (newValues.vmId) newValues.vmId = parseInt(newValues.vmId.toString());
      return newValues;
    }
    return {};
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ pr: "32px !important" }}>
          <Grid container>
            <Grid item xs={1} sx={{ mt: "0.3vh" }}>
              <Build />
            </Grid>
            <Grid item xs={8} sx={{ padding: "0.5vh", mt: "auto", mb: "auto" }}>
              <Typography variant="h4">{`${ipData.ip} - ${ipData.ovhAccount}`}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                justifyContent="right"
                alignItems="center"
                sx={{ padding: "0.5vh", mt: "auto", mb: "auto" }}
              >
                {values && (
                  <>
                    <Typography variant="body1" sx={{ mr: "0.3vw" }}>
                      Etat
                    </Typography>
                    <ButtonWithConfirm
                      confirmText="Voulez-vous vraiment changer l'état de l'IP ?"
                      variant="text"
                      onUpdateState={setFieldDisabled}
                      onConfirm={() => {
                        values.isRunning = !values.isRunning;
                        updateValues(values, ["isRunning"]);
                      }}
                      sx={{ p: 0 }}
                    >
                      <Switch
                        name="state"
                        checked={values?.isRunning ?? false}
                        disabled={fieldsDisabled}
                        sx={{
                          "& .css-1r157f1-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                            {
                              backgroundColor: "cobalt.greenIcon",
                              borderColor: "cobalt.greenIcon",
                            },
                        }}
                      />
                    </ButtonWithConfirm>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        {values && (
          <>
            <ModalIpMoveService
              values={values}
              setValues={setValues}
              openSnackbar={fieldsDisabled}
              onConfirmOpen={setFieldDisabled}
              onValidateChanges={updateValues}
            />
            <ModalIpInformations
              values={values}
              openSnackbar={fieldsDisabled}
              onConfirmOpen={setFieldDisabled}
              onValidateChanges={updateValues}
            />
          </>
        )}
        {alert && <MyAlert alert={alert} setAlert={setAlert} top={"500px"} />}
      </Dialog>
    </>
  );
};

interface ModalMoveServiceProps {
  values: ValuesEditIp;
  setValues: (values: ValuesEditIp) => void;
  openSnackbar: boolean;
  onValidateChanges: (values: ValuesEditIp, fields: Array<string>) => void;
  onConfirmOpen: (isOpen: boolean) => void;
}

const ModalIpMoveService = (props: ModalMoveServiceProps) => {
  const { values, setValues, openSnackbar, onConfirmOpen, onValidateChanges } =
    props;
  const { servers } = useServers();

  const handleInputChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <DialogContent dividers>
      <Typography
        variant="h4"
        sx={{ mb: "1vh", color: "cobalt.bleuDigital100" }}
      >
        Déplacement de l'IP
      </Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{ mt: "2vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="body1">Déplacer vers</Typography>
        </Grid>
        <Grid item xs={9}>
          <InputLabel>Serveur</InputLabel>
          <MuiSelect
            autoWidth
            sx={{ width: "100%" }}
            name="service"
            MenuProps={MenuProps}
            disabled={openSnackbar}
            value={values["service"]}
            onChange={handleInputChange}
          >
            {servers &&
              servers.map((server: Server, key) => (
                <MenuItem
                  key={key}
                  value={server.name}
                >{`${server.name} - ${server.reverse}`}</MenuItem>
              ))}
          </MuiSelect>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <ButtonWithConfirm
            confirmText="Voulez-vous vraiment déplacer l'IP ?"
            onUpdateState={onConfirmOpen}
            onConfirm={() => onValidateChanges(values, ["service"])}
            color="primary"
            disabled={openSnackbar}
          >
            Déplacer
          </ButtonWithConfirm>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

interface ModalIpInformationsProps {
  values: ValuesEditIp;
  openSnackbar: boolean;
  onConfirmOpen: (isOpen: boolean) => void;
  onValidateChanges: (values: any, fields: Array<string>) => void;
}

const ModalIpInformations = (props: ModalIpInformationsProps) => {
  const { values, openSnackbar, onConfirmOpen, onValidateChanges } = props;
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      hostname: values["hostname"] || "",
      domain: values["domain"] || "",
      vmId: values["vmId"] ? values["vmId"].toString() : "",
      machineType: values["machineType"] || "",
      description: values["description"] || "",
    },
    validationSchema: ipValidationSchema,
    onSubmit: (validateValues) => {
      let valuesToSubmit: ValuesEditIp = {
        isRunning: values.isRunning,
        service: values.service,
        ...validateValues,
        vmId: parseInt(validateValues.vmId),
      };

      onValidateChanges(valuesToSubmit, [
        "hostname",
        "domain",
        "vmId",
        "machineType",
        "description",
      ]);
    },
  });

  return (
    <DialogContent dividers>
      <Typography
        variant="h4"
        sx={{ mb: "1vh", color: "cobalt.bleuDigital100" }}
      >
        Informations
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Textfield
              name="hostname"
              label="Nom d'hôte"
              disabled={openSnackbar}
              formik={formik}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Textfield
              label="Domaine"
              name="domain"
              disabled={openSnackbar}
              formik={formik}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Textfield
              label="ID de la VM"
              name="vmId"
              disabled={openSnackbar}
              formik={formik}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ marginBottom: "1vh" }}>
              Type de la machine
            </InputLabel>
            <Select
              name="machineType"
              formik={formik}
              disabled={openSnackbar}
              sx={{ width: "100%" }}
              valuesPossible={["Physique", "Virtuelle"]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Textfield
              label="Description OVH"
              name="description"
              disabled={openSnackbar}
              formik={formik}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <ButtonWithConfirm
              color="primary"
              onUpdateState={onConfirmOpen}
              confirmText="Voulez-vous vraiment modifier les informations de l'IP ?"
              disabled={openSnackbar}
              type="submit"
            >
              Modifier
            </ButtonWithConfirm>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: "2%",
      border: "solid 1px",
      borderColor: " cobalt.userBlue",
      maxHeight: "40vh",
    },
  },
};

export default ModalEditIp;
