import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GridData } from "@/components/GridData";
import ModalEditProject from "@/components/projects/ModalEditProject";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useProject } from "@/services/ProjectsService";
import { FieldColumn } from "@/types/others.types";

const columns: Array<FieldColumn> = [
  {
    field: "hostname",
    headerName: "Nom de domaine",
    width: 300,
    headerAlign: "center",
  },
  {
    field: "ip",
    headerName: "Ip",
    width: 140,
    headerAlign: "center",
  },
  {
    field: "description",
    headerName: "Description",
    width: 250,
    headerAlign: "center",
  },
  {
    field: "os",
    headerName: "OS",
    width: 150,
    headerAlign: "center",
  },
];

const tmpRows = [
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
  {
    ip: "192.353.43.2",
    os: "Debian 11",
    hostname: "dns.domain.dns",
    description: " Front avec un nginx",
  },
];

const Project = () => {
  const { id } = useParams<{ id: string }>();
  const { project } = useProject(id || "", { enabled: !!id });
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const leads = project?.members.filter((m) => m.isLead);
  const devs = project?.members.filter((m) => !m.isLead);

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            {project?.name}
            <IconButton
              onClick={() => setEditModalOpen(true)}
              title="Modifier le projet"
            >
              <CobaltIcon
                color="cobalt.bleuDigital100"
                sx={{ textAlign: "center", fontSize: "24px" }}
                name="edit-3"
              />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Stack spacing={2}>
            <Box
              component="img"
              src={
                project?.image && project?.image.data.length > 0
                  ? `data:image/png;base64,${project.image.data}`
                  : "/images/illus-project.png"
              }
              alt="Illustration projet"
              sx={{
                width: "100%",
              }}
            />

            <Typography
              component="pre"
              sx={{ fontFamily: "inherit" }}
              variant="body1"
            >
              {project?.description}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: "24px", color: "cobalt.userBlue" }}
            >
              Projet créé le {dayjs(project?.created).format("DD/MM/YYYY")}
            </Typography>
            <Stack
              justifyContent="center"
              sx={{ minHeight: "40px", mb: "16px" }}
            >
              <Typography
                variant="h1"
                sx={{
                  width: "100%",
                  color: "cobalt.ultramarine",
                }}
              >
                Membres
              </Typography>
            </Stack>

            <Grid
              container
              sx={{
                backgroundColor: "cobalt.bleuDigital10",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 4px 8px rgb(102 109 146 / 8%)",
                mb: "16px",
              }}
              spacing={2}
            >
              <Typography
                variant="h3"
                sx={{
                  width: "100%",
                  color: "cobalt.userBlue",
                  mb: "8px",
                }}
              >
                Responsable(s)
              </Typography>
              {leads?.map((member, key) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    {member.lastName} {member.firstName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              sx={{
                padding: "16px",
                mb: "16px",
              }}
              spacing={2}
            >
              <Typography
                variant="h3"
                sx={{
                  width: "100%",
                  color: "cobalt.userBlue",
                  mb: "8px",
                }}
              >
                Développeur(s)
              </Typography>
              {devs?.map((member, key) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    {member.lastName} {member.firstName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              minHeight: "100%",
              backgroundColor: "cobalt.grey20",
              width: "1px",
              m: "0 16px",
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: "16px" }}
          >
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                color: "cobalt.ultramarine",
              }}
            >
              VMs
            </Typography>
            <Button
              variant="outlined"
              startIcon={<CobaltIcon name="bell" />}
              sx={{ minWidth: "270px" }}
            >
              S'abonner aux notifications
            </Button>
          </Stack>
          <GridData rows={tmpRows} columns={columns} />
        </Grid>
      </Grid>

      {isEditModalOpen && (
        <ModalEditProject
          mode="edit"
          open={isEditModalOpen}
          project={project!}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </Container>
  );
};

export default Project;
