import { GridData } from "@/components/GridData";
import MyAlert from "@/components/MyAlert";
import { useReverses, useTasks, useUpdateReverse } from "@/services/OvhService";
import { Alert, FieldColumn, Severity } from "@/types/others.types";
import { Ip } from "@/types/webservices_ovh_db.types";
import { Dialog } from "@docaposte-agility/da-design-system";
import { Edit } from "@mui/icons-material";
import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useQueryClient } from "react-query";
import ButtonEditReverseWithConfirm from "./ButtonEditReverseWithConfirm";

interface ModalMoreIpProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  ipData: Ip;
}

const DEFAULT_VALUES_ALERT: Alert = {
  open: false,
  severity: "error" as Severity,
  text: "",
};

const ModalMoreIp = (props: ModalMoreIpProps) => {
  const { openModal, setOpenModal, ipData } = props;
  const [alert, setAlert] = useState<Alert>(DEFAULT_VALUES_ALERT);
  const { reverses, isLoading: isLoadingReverse } = useReverses(ipData.ip);
  const { tasks, isLoading: isLoadingTasks } = useTasks(ipData.ip);
  const queryClient = useQueryClient();

  const { mutate: updateReverse } = useUpdateReverse({
    onSuccess: () => {
      queryClient.invalidateQueries("reverses");
      setAlert({
        open: true,
        severity: "success",
        text: "Modification du reverse effectuée",
      });
    },
    onError: () => {
      setAlert({
        open: true,
        severity: "error",
        text: "Erreur : la modification n'a pas été effectuée",
      });
    },
  });

  const changeReverse = (ipReverse: string, newReverse: string) => {
    updateReverse({
      ip: ipReverse,
      values: {
        ipReverse: encodeURIComponent(ipReverse),
        reverse: newReverse,
        ovhAccount: ipData["ovhAccount"],
      },
    });
  };

  const columnsReverses = getColumnsReverse(changeReverse);
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="md"
      >
        <DialogTitle>
          <div>
            <Typography variant="h4">{`${ipData.ip} - ${ipData.ovhAccount}`}</Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="h4"
            sx={{ color: "cobalt.bleuDigital100", mr: "40vw" }}
          >
            Reverses
          </Typography>
          <GridData
            rows={reverses || []}
            columns={columnsReverses}
            fixedSize={4}
            loading={isLoadingReverse}
          />
        </DialogContent>
        <DialogContent dividers>
          <Typography variant="h4" sx={{ color: "cobalt.bleuDigital100" }}>
            Liste des tâches
          </Typography>
          <GridData
            rows={tasks || []}
            columns={columnsTasks}
            fixedSize={4}
            loading={isLoadingTasks}
          />
        </DialogContent>
        <MyAlert alert={alert} setAlert={setAlert} top="200px" />
      </Dialog>
    </>
  );
};

const getColumnsReverse = (
  handleCloseSnackbar: (reverse: string, newReverse: string) => void,
): Array<FieldColumn> => {
  return [
    {
      field: "ipReverse",
      headerName: "IP",
      width: 360,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "reverse",
      headerName: "Reverse",
      width: 410,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "valid",
      headerName: "",
      width: 5,
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <ButtonEditReverseWithConfirm
              reverse={params.row.reverse}
              icon
              onConfirm={(newReverse) =>
                handleCloseSnackbar(params.row.ipReverse, newReverse)
              }
              aria-label="config button"
              sx={{ color: "cobalt.ultramarine" }}
            >
              <Edit />
            </ButtonEditReverseWithConfirm>
          </>
        );
      },
    },
  ];
};

const columnsTasks: Array<FieldColumn> = [
  {
    field: "taskId",
    headerName: "N°",
    width: 140,
    headerAlign: "center",
  },
  {
    field: "function",
    headerName: "Operation",
    width: 160,
    headerAlign: "center",
  },
  {
    field: "destination",
    headerName: "Destination",
    width: 163,
    headerAlign: "center",
    valueGetter: (params) => {
      return params.row.destination.serviceName;
    },
  },
  {
    field: "startDate",
    headerName: "Début",
    width: 110,
    headerAlign: "center",
  },
  {
    field: "doneDate",
    headerName: "Fin",
    width: 110,
    headerAlign: "center",
  },
  {
    field: "comment",
    headerName: "Description",
    width: 150,
    headerAlign: "center",
  },
];

export default ModalMoreIp;
