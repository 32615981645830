import { Typography, Grid, Box, Stack } from "@mui/material";
import { useIncidentsStats } from "@/services/IncidentsService";
import ChartLevel14Days from "./ChartLevel14Days";
import PieIncidentsLevel from "./PieIncidentsLevel";

const StatsIncidents = () => {
  const { stats: stats7days } = useIncidentsStats(7);
  const { stats: stats30days } = useIncidentsStats(30);
  const { stats: stats365days } = useIncidentsStats(365);

  return (
    <Grid container alignItems="center" sx={{ mt: "6px" }}>
      <Grid item xs={3} spacing={2} sx={{ p: " 0 40px" }}>
        <PieIncidentsLevel />
      </Grid>

      <Grid item xs={4} sx={{ p: "0 5%", height: "100%" }}>
        <Stack alignItems="center" spacing={3}>
          <IncidentsTriggeredInLastDays
            nbDays={7}
            nbIncidentsTriggered={stats7days?.count.total}
          />
          <IncidentsTriggeredInLastDays
            nbDays={30}
            nbIncidentsTriggered={stats30days?.count.total}
          />
          <IncidentsTriggeredInLastDays
            nbDays={365}
            nbIncidentsTriggered={stats365days?.count.total}
          />
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <ChartLevel14Days />
      </Grid>
    </Grid>
  );
};

export default StatsIncidents;

interface IncidentsTriggeredInLastDaysProps {
  nbDays: number;
  nbIncidentsTriggered?: number;
}

const IncidentsTriggeredInLastDays = (
  props: IncidentsTriggeredInLastDaysProps,
) => {
  const { nbDays, nbIncidentsTriggered } = props;
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "left",
        boxShadow: "0 4px 8px rgb(102 109 146 / 8%)",
        backgroundColor: "cobalt.bleuDigital10",
        p: "8px 16px",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant={`h5`}
        sx={{ mr: "0.5vw", color: "cobalt.ultramarine" }}
      >
        {`${nbDays} derniers jours`}
      </Typography>
      <Box sx={{ textAlign: "center", mt: "8px" }}>
        <Typography
          variant={`h5`}
          color="cobalt.userBlue"
          sx={{ width: "100%", fontSize: "26px" }}
        >
          {nbIncidentsTriggered}
        </Typography>
        <Typography
          variant={"subtitle1"}
          color="cobalt.lynch"
          sx={{ fontSize: "13px" }}
        >
          déclenchements
        </Typography>
      </Box>
    </Stack>
  );
};
