import {
  Button,
  ButtonProps,
  ClickAwayListener,
  Divider,
  IconButton,
  IconButtonProps,
  Paper,
  SnackbarContent as TooltipContent,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  TextField,
  Box,
} from "@mui/material";
import { useState } from "react";

interface ButtonWithConfirmBaseProps extends ButtonProps {
  onUpdateState?: (isOpen: boolean) => void;
  reverse: string;
  open?: boolean;
}

type ButtonTypeChoice =
  | ({ icon: true } & IconButtonProps)
  | ({ icon?: false } & ButtonProps);

type ButtonTypeConfirm =
  | ({ type: "submit" } & {})
  | ({ type?: "button" | "reset" } & { onConfirm: (value: string) => void });

type ButtonWithConfirmProps = ButtonWithConfirmBaseProps &
  ButtonTypeConfirm &
  ButtonTypeChoice;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    padding: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const ButtonEditReverseWithConfirm = ({
  children,
  open,
  reverse,
  onUpdateState,
  ...rest
}: ButtonWithConfirmProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [target, setTarget] = useState<HTMLButtonElement | null>(null);
  const [newReverse, setNewReverse] = useState<string>(reverse);

  const isConfirmOpen = open ?? isOpen;
  const onConfirm = rest.type !== "submit" ? rest.onConfirm : () => {};
  const { icon, ...buttonProps } = rest;

  const handleConfirm = () => {
    if (buttonProps.type === "submit") {
      target?.form?.requestSubmit(target);
    } else {
      onConfirm(newReverse);
    }
    setTarget(null);

    setOpen(false);
    onUpdateState?.(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (target) return true;
    setOpen(true);
    event.preventDefault();
    setTarget(event.currentTarget || event.target);
    buttonProps.onClick?.(event);
    onUpdateState?.(true);
    return false;
  };

  const handleCancel = () => {
    setOpen(false);
    onUpdateState?.(false);
    setTarget(null);
  };

  return (
    <CustomTooltip
      open={isConfirmOpen}
      arrow
      title={
        <ClickAwayListener onClickAway={handleCancel}>
          <Paper elevation={3}>
            <TooltipContent
              message={
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body2">Nouveau nom :</Typography>
                    <TextField
                      type="text"
                      name="newReverse"
                      value={newReverse}
                      onChange={(val) => setNewReverse(val.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Divider />
                </>
              }
              action={
                <>
                  <Button
                    variant="text"
                    onClick={handleConfirm}
                    sx={{ borderRadius: 0, width: "100%" }}
                  >
                    Confirmer
                  </Button>
                  <Divider orientation="vertical" />
                  <Button
                    variant="text"
                    onClick={handleCancel}
                    sx={{ borderRadius: 0, width: "100%" }}
                  >
                    Annuler
                  </Button>
                </>
              }
            />
          </Paper>
        </ClickAwayListener>
      }
    >
      {icon ? (
        <IconButton
          {...buttonProps}
          onClick={handleClick}
          disabled={isConfirmOpen || buttonProps.disabled}
        >
          {children}
        </IconButton>
      ) : (
        <Button
          {...buttonProps}
          onClick={handleClick}
          disabled={isConfirmOpen || buttonProps.disabled}
        >
          {children}
        </Button>
      )}
    </CustomTooltip>
  );
};

export default ButtonEditReverseWithConfirm;
