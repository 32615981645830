import Axios, { InternalAxiosRequestConfig } from "axios";
import AuthService from "@/services/AuthService";

export const executeRequestInterceptor = () => {
  Axios.interceptors.request.use(
    (config) => {
      const token = AuthService.getToken();
      const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};

      return {
        ...config,
        headers: {
          ...authHeaders,
          ...config.headers,
        },
      } as InternalAxiosRequestConfig;
    },
    (error) => Promise.reject(error),
  );
};
