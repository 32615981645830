import { useMemo } from "react";

interface SearchResultProps {
  base: string;
  search: string;
}

const SearchResult = ({ base, search }: SearchResultProps) => {
  const display = useMemo(() => {
    if (search.length === 0) return [base];
    const lowerSearch = search.toLowerCase();
    const result = new Array<JSX.Element | string>();

    for (let i = 0; i < base.length; i++) {
      const sub = base.substring(i, i + search.length);
      if (sub.toLowerCase() === lowerSearch) {
        result.push(
          <mark key={i + search} style={{ borderRadius: "3px" }}>
            {sub}
          </mark>,
        );
        i += search.length - 1;
      } else {
        result.push(base[i]);
      }
    }

    return result;
  }, [base, search]);

  return <>{display.map((elem) => elem)}</>;
};

export default SearchResult;
