import {
  Project,
  ProjectFormData,
  ProjectFromApi,
} from "@/types/projects.types";
import { getBase64StringImage } from "@/utils/image.utils";
import { deserializeMembers, serializeMembers } from "@/utils/projects.utils";

export const mapFormDataToProjectFromApi = (
  formData: ProjectFormData,
): ProjectFromApi => mapProjectToProjectFromApi(formData);

export const mapProjectToFormData = (project: Project): ProjectFormData => ({
  id: project.id,
  description: project.description,
  name: project.name,
  created: project.created,
  members: [...project.members],
  image: project.image,
  vms: [],
  isImageModified: false,
  memberForm: {
    firstName: "",
    lastName: "",
    isLead: false,
  },
});

export const mapProjectFromApiToProject = (
  project: ProjectFromApi,
): Project => {
  const image = getBase64StringImage(project.image);

  return {
    id: project.id,
    description: project.description,
    name: project.name,
    created: project.created,
    image: (image && { data: image, type: "base64" }) || undefined,
    members: deserializeMembers(project.leadsNames, project.devsNames),
  };
};

export const mapProjectToProjectFromApi = (
  project: Project,
): ProjectFromApi => {
  return {
    id: project.id,
    description: project.description,
    name: project.name,
    created: project.created,
    image: project.image || { data: "", type: "image/png" },
    ...serializeMembers(project.members),
  };
};
