import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CardProject from "@/components/projects/CardProject";
import ModalEditProject from "@/components/projects/ModalEditProject";
import { useState } from "react";
import { useProjects } from "@/services/ProjectsService";

const Projects = () => {
  const { projects, isLoading } = useProjects();
  const [searchString, setSearchString] = useState("");
  const [isProjectModalOpen, setProjectModalOpen] = useState(false);

  const filteredProjects = projects.filter((project) => {
    return project.name.toLowerCase().includes(searchString.toLowerCase());
  });

  return (
    <>
      <Stack
        width="100%"
        sx={{
          padding: "1.5rem 3rem",
          boxSizing: "border-box",
          height: "100vh",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "16px" }}
        >
          <Grid item>
            <Typography
              variant="h1"
              color="cobalt.bleuDigital100"
              sx={{ textAlign: "center", fontSize: "32px" }}
            >
              Liste des projets
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ height: "24px", display: "flex", alignItems: "center" }}
          >
            <TextField
              placeholder="Search"
              fullWidth
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    {searchString ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchString("")}
                          sx={{ cursor: "pointer" }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <CobaltIcon name="search" />
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />

            <Button
              startIcon={<CobaltIcon name="plus" />}
              variant="text"
              onClick={() => setProjectModalOpen(true)}
              sx={{ height: "24px", pt: 0, pb: 0, minWidth: "200px" }}
            >
              Créer un projet
            </Button>
          </Grid>
        </Grid>
        {filteredProjects.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="h5">
                {projects.length === 0
                  ? "Aucun projet n'est disponible pour le moment."
                  : "Aucun résultat trouvé pour la recherche."}
              </Typography>
            )}
          </Box>
        ) : (
          <Grid container spacing={3}>
            {filteredProjects.map((project, key) => (
              <Grid item xs={12} md={4} key={key}>
                <CardProject project={project} />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
      {isProjectModalOpen && (
        <ModalEditProject
          open={isProjectModalOpen}
          mode="create"
          onClose={() => setProjectModalOpen(false)}
        />
      )}
    </>
  );
};

export default Projects;
