import { Tool } from "@/types/tools.types";

export const getModifiedFields = (tool: Tool, values: Tool): Partial<Tool> => {
  const modifiedFields: Partial<Tool> = {};

  if (tool.name !== values.name) modifiedFields.name = values.name;

  if (tool.description !== values.description)
    modifiedFields.description = values.description;

  if (tool.link !== values.link) modifiedFields.link = values.link;

  if (tool.section !== values.section) modifiedFields.section = values.section;

  if (tool.contact_label !== values.contact_label)
    modifiedFields.contact_label = values.contact_label;

  if (tool.contact_link !== values.contact_link)
    modifiedFields.contact_link = values.contact_link;

  if (tool.logo !== values.logo) modifiedFields.logo = values.logo;

  if (tool.manual_link !== values.manual_link)
    modifiedFields.manual_link = values.manual_link;

  return modifiedFields;
};
