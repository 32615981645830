import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "@docaposte-agility/da-design-system";
import { BrowserRouter, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RoutesProvider from "@/components/RoutesProvider";
import { useEffect } from "react";
import { executeRequestInterceptor } from "./config/axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount > 2 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

const App = () => {
  useEffect(() => executeRequestInterceptor(), []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <RoutesProvider />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
