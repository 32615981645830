import {
  Typography,
  Button,
  Snackbar,
  Divider,
  AlertTitle,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface SnackbarConfirmProps {
  openSnackbar: boolean;
  handleCloseSnackbar: (confirm: boolean) => void;
  title: string;
  text: string;
  top: string;
}

const SnackbarConfirm = (props: SnackbarConfirmProps) => {
  const { openSnackbar, handleCloseSnackbar, title, text, top } = props;

  const theme = useTheme();
  const snackbarBreakpoint = useMediaQuery(theme.breakpoints.down(1600));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: snackbarBreakpoint ? "center" : "right",
      }}
      open={openSnackbar}
      sx={{
        position: "absolute",
        right: "-375px !important",
        top: `${top} !important`,
      }}
      message={
        <>
          <Alert onClose={() => handleCloseSnackbar(false)}>
            <AlertTitle>{title}</AlertTitle>
            <Typography variant="body2">{text}</Typography>
          </Alert>
          <Divider light />
        </>
      }
      action={
        <>
          <Button onClick={() => handleCloseSnackbar(true)} variant="text">
            Confirmer
          </Button>
          <Divider light orientation="vertical" />
          <Button onClick={() => handleCloseSnackbar(false)} variant="text">
            Annuler
          </Button>
          <Divider light />
        </>
      }
    />
  );
};

export default SnackbarConfirm;
