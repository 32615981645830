import { Container, Box, Typography } from "@mui/material";

const HomePage = () => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      margin: "auto",
    }}
  >
    <Box
      component="img"
      sx={{
        width: "35%",
      }}
      alt="Logo da-exploitation"
      src="/images/logo-da-exploitation.svg"
    />
    <Typography
      variant="h4"
      color="cobalt.userBlue"
      sx={{ mt: "8vh", fontSize: "2em" }}
    >
      Dashboard pour l'équipe infrastructure.
    </Typography>
  </Container>
);

export default HomePage;
