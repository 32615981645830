import { CalendarRange, CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Box,
  IconButton,
  Popover,
  TextFieldProps,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { getIn } from "formik";
import { useEffect, useState } from "react";
import Textfield from "./Textfield";

export interface DateRangeInputProps {
  formik: any;
  startName: string;
  endName: string;
  dateFormat?: string;
  startProps?: TextFieldProps;
  endProps?: TextFieldProps;
}

const DateRangeInput = (props: DateRangeInputProps) => {
  const { formik, startName, endName, dateFormat, startProps, endProps } =
    props;
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [startInput, setStartInput] = useState("");
  const [endInput, setEndInput] = useState("");

  const format = dateFormat ?? "DD/MM/YYYY";

  useEffect(() => {
    const value = getIn(formik.values, startName);
    if (value) {
      setStartInput(dayjs(value).format(format));
    }
  }, [format, formik.values, startName]);

  useEffect(() => {
    const value = getIn(formik.values, endName);
    if (value) {
      setEndInput(dayjs(value).format(format));
    }
  }, [format, formik.values, endName]);

  const handleClickCalendarButton = (e: any) => {
    if (isCalendarOpen) {
      setCalendarAnchorEl(null);
    } else {
      setCalendarAnchorEl(
        e.target.parentElement.parentElement.parentElement.parentElement,
      );
    }
    setCalendarOpen(!isCalendarOpen);
  };

  const handleOnDateChange = (date: Date[]) => {
    formik.setFieldValue(startName, date[0]);
    setStartInput(dayjs(date[0]).format(format));
    formik.setFieldValue(endName, date[1]);
    setEndInput(dayjs(date[1]).format(format));
  };

  const handleTextFieldChange = (e: any, selected: "start" | "end") => {
    if (selected === "start") {
      setStartInput(e.target.value);
    } else {
      setEndInput(e.target.value);
    }

    const date = dayjs(e.target.value, format);

    if (!date.isValid()) return;

    if (selected === "start") {
      formik.setFieldValue(startName, date.toDate());
    } else {
      formik.setFieldValue(endName, date.toDate());
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }} width="100%">
        <Textfield
          formik={formik}
          fullWidth
          name={startName}
          value={startInput}
          {...startProps}
          onClick={handleClickCalendarButton}
          onChange={(e: any) => handleTextFieldChange(e, "start")}
          placeholder={format}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickCalendarButton}>
                <CobaltIcon
                  name="calendar"
                  sx={{
                    cursor: "pointer",
                    color: isCalendarOpen ? "cobalt.ultramarine" : "inherit",
                  }}
                />
              </IconButton>
            ),
          }}
        />
        <Typography component="span" color="cobalt.dodgerBlueIcon">
          <CobaltIcon name="arrow-right" fontSize={24} />
        </Typography>
        <Textfield
          formik={formik}
          fullWidth
          name={endName}
          value={endInput}
          {...endProps}
          onClick={handleClickCalendarButton}
          onChange={(e: any) => handleTextFieldChange(e, "end")}
          placeholder={format}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickCalendarButton}>
                <CobaltIcon
                  name="calendar"
                  sx={{
                    cursor: "pointer",
                    color: isCalendarOpen ? "cobalt.ultramarine" : "inherit",
                  }}
                />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Popover
        open={isCalendarOpen}
        onClose={() => setCalendarOpen(false)}
        anchorEl={calendarAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={3}
        sx={{ mt: 1 }}
      >
        <CalendarRange
          lang="fr"
          date={[
            getIn(formik.values, startName) ?? new Date(),
            getIn(formik.values, endName) ?? new Date(),
          ]}
          setDate={handleOnDateChange}
        />
      </Popover>
    </>
  );
};

export default DateRangeInput;
