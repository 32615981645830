import { Grid, Typography } from "@mui/material";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { NewComerTicket, TicketFormData } from "@/types/tickets.types";

interface RedmineRightFormProps {
  formik: FormikProps<TicketFormData<NewComerTicket>>;
  attributeFormikPrefix: string;
}

export const getRedmineDefaultRightValues = () => ({
  project: "",
});

export const getRedminePlateformRightsDef = (prefix: string) => ({
  getDefaultRightValues: getRedmineDefaultRightValues,
  getRequiredRightsComponent: (
    formik: FormikProps<TicketFormData<NewComerTicket>>,
  ) => (
    <RedmineRequiredRightsForm formik={formik} attributeFormikPrefix={prefix} />
  ),
});

const RedmineRequiredRightsForm = ({
  formik,
  attributeFormikPrefix,
}: RedmineRightFormProps) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" color="cobalt.dodgerBlueIcon">
          Redmine
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Textfield
          label="Projet"
          formik={formik}
          name={`${attributeFormikPrefix}.project`}
        />
      </Grid>
    </Grid>
  );
};

export default RedmineRequiredRightsForm;
