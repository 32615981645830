import { mapToolArrayToToolList } from "@/mappers/tools.mappers";
import { Tool } from "@/types/tools.types";
import axios, { AxiosResponse } from "axios";
import {
  MutateOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";

const endpoint: string | undefined = window.__ENV__.REACT_APP_ENDPOINT_BACK;

export const useTools = (
  config: UseQueryOptions<
    AxiosResponse<Tool[]>,
    unknown,
    AxiosResponse<Tool[]>,
    Array<string>
  > = {},
) => {
  const { data, refetch, ...rest } = useQuery(
    ["tools"],
    () => axios.get(`${endpoint}/tools`),
    { ...config },
  );
  const tools = mapToolArrayToToolList(data?.data || []);
  return { tools, ...rest };
};

export const useUpdateTool = (
  config: MutateOptions<
    AxiosResponse<Tool>,
    unknown,
    { id: string; values: Partial<Tool> }
  > = {},
) => {
  return useMutation(
    ({ id, values }) => axios.patch(`${endpoint}/tools/${id}`, values),
    { ...config },
  );
};

export const useCreateTool = (
  config: MutateOptions<AxiosResponse<Tool>, unknown, { values: Tool }> = {},
) => {
  return useMutation(({ values }) => axios.post(`${endpoint}/tools`, values), {
    ...config,
  });
};

export const useDeleteTool = (
  config: MutateOptions<AxiosResponse<Tool>, unknown, string> = {},
) => {
  return useMutation((id) => axios.delete(`${endpoint}/tools/${id}`), {
    ...config,
  });
};
