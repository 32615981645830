import CardTool from "@/components/tools/CardTool";
import ModalEditTool from "@/components/tools/ModalEditTool";
import ModalTool from "@/components/tools/ModalTool";
import AuthService from "@/services/AuthService";
import { useTools } from "@/services/ToolService";
import { RolesEnum } from "@/types/others.types";
import { Tool } from "@/types/tools.types";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

interface ModalState {
  state: "edit" | "add" | "view" | "closed";
  tool?: Tool;
}

const defaultTool: Tool = {
  name: "",
  description: "",
  link: "",
  section: "",
  contact_label: "",
  contact_link: "",
  logo: "",
  manual_link: "",
};

const Tools = () => {
  const [search, setSearch] = useState("");
  const { tools, isLoading } = useTools();
  const canEdit = AuthService.hasRole(RolesEnum.Admin);
  const [modalState, setModalState] = useState<ModalState>({
    state: "closed",
    tool: undefined,
  });

  const filteredTools = useMemo(() => {
    const lowerSearch = search.toLowerCase();
    return Object.entries(tools).reduce((acc, [section, sectionTools]) => {
      const filteredTools = sectionTools.filter((tool) =>
        tool.name.toLowerCase().includes(lowerSearch),
      );

      if (filteredTools.length > 0) {
        acc[section] = filteredTools;
      }

      return acc;
    }, {} as Record<string, Tool[]>);
  }, [search, tools]);

  const sectionNames = useMemo(
    () => Object.keys(filteredTools).sort((a, b) => a.localeCompare(b)),
    [filteredTools],
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        minHeight: "calc(100vh - 3rem)",
        height: "fit-content",
        pb: "1.5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            Liste des outils
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ height: "24px", display: "flex", alignItems: "center" }}
        >
          {canEdit && (
            <Button
              endIcon={<CobaltIcon name="plus" />}
              variant="text"
              onClick={() => setModalState({ state: "add", tool: defaultTool })}
              sx={{ height: "24px", pt: 0, pb: 0, minWidth: "200px" }}
            >
              Ajouter un outil
            </Button>
          )}

          <TextField
            placeholder="Search"
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <CobaltIcon name="search" />
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
      {sectionNames.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexGrow: 1,
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="h5">
              {search.length === 0
                ? "Aucun outil n'est disponible pour le moment."
                : "Aucun résultat trouvé pour la recherche."}
            </Typography>
          )}
        </Box>
      ) : (
        <Grid container spacing={3}>
          {sectionNames.map(
            (section, key) =>
              filteredTools[section].length > 0 && (
                <>
                  <Grid item xs={12} md={12} key={"section-" + key}>
                    <Typography
                      color="cobalt.userBlue"
                      variant="h2"
                      sx={{ mt: "16px" }}
                    >
                      {section}
                    </Typography>
                  </Grid>
                  {filteredTools[section].map((tool, key) => (
                    <Grid item xs={12} md={3} key={tool.name + "-" + key}>
                      <CardTool
                        search={search}
                        tool={tool}
                        openModal={() => setModalState({ state: "view", tool })}
                      />
                    </Grid>
                  ))}
                </>
              ),
          )}
        </Grid>
      )}

      {modalState.state === "view" && modalState.tool && (
        <ModalTool
          open={true}
          tool={modalState.tool}
          onClose={() => setModalState({ state: "closed" })}
          editTool={(tool) => setModalState({ state: "edit", tool })}
        />
      )}
      {(modalState.state === "edit" || modalState.state === "add") && (
        <ModalEditTool
          mode={modalState.state}
          tool={modalState.tool}
          sectionNames={Object.keys(tools)}
          onClose={() => setModalState({ state: "closed" })}
        />
      )}
    </Container>
  );
};

export default Tools;
