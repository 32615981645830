import { ImageType } from "@/types/projects.types";

export const getBase64StringImage = (image?: ImageType) =>
  image
    ? btoa(
        new Uint8Array(image.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          "",
        ),
      )
    : null;
