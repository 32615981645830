import {
  Container,
  Typography,
  Box,
  Stack,
  Button,
  Switch,
} from "@mui/material";
import CardIncident from "@/components/incidents/CardIncident";
import StatsIncidents from "@/components/incidents/StatsIncidents";
import { useIncidents } from "@/services/IncidentsService";
import { Incident } from "@/types/incidents.types";
import { RolesEnum } from "@/types/others.types";
import { ModalCreateIncidentObject } from "@/types/incidents.types";
import ModalIncident from "@/components/incidents/ModalIncident";
import { useState } from "react";
import AuthService from "@/services/AuthService";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const Incidents = () => {
  const [modal, setModal] = useState<ModalCreateIncidentObject>({
    open: false,
    scheduledIncident: false,
  });
  const [displayStats, setDisplayStats] = useState(true);

  const toggleStats = () => {
    setDisplayStats((prev) => !prev);
  };

  const { incidents } = useIncidents({
    refetchInterval: 30000,
    onSuccess: (data) =>
      data.data.sort((incidentA, incidentB) =>
        incidentA.created < incidentB.created ? 1 : -1,
      ),
  });

  const isAdmin: boolean = AuthService.hasRole(RolesEnum.Admin);

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Stack justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ width: "100%", mb: "16px" }}
        >
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "left", fontSize: "32px" }}
          >
            Suivi des incidents
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h5"
              color="cobalt.bleuDigital100"
              sx={{
                width: "100%",
                textAlign: "left",
                mr: "12px",
                color: "cobalt.ultramarine",
              }}
            >
              Afficher les statistiques
            </Typography>
            <Switch
              name="toggle"
              onChange={toggleStats}
              checked={displayStats}
            />
          </Stack>
        </Stack>

        <Stack sx={{ width: "100%" }}>
          {displayStats && (
            <Box
              sx={{
                paddingRight: "2vw",
                paddingLeft: "2vw",
                width: "100%",
                backgroundColor: "cobalt.white",
                mb: "2vh",
                borderRadius: "8px",
                padding: "2vh",
                boxShadow: "0 4px 8px rgb(102 109 146 / 8%)",
              }}
            >
              <StatsIncidents />
            </Box>
          )}
          <Stack direction="row" justifyContent="space-between">
            <OngoingIncidents
              incidents={incidents}
              isAdmin={isAdmin}
              setModal={setModal}
            />
            <Box
              sx={{
                minHeight: "100%",
                backgroundColor: "cobalt.grey20",
                width: "1px",
                m: "0 24px",
                mt: "32px",
              }}
            />
            <ScheduledIncidents
              incidents={incidents}
              isAdmin={isAdmin}
              setModal={setModal}
            />
          </Stack>
          {modal.open && (
            <ModalIncident
              open={modal.open}
              handleClose={() =>
                setModal({ open: false, scheduledIncident: false })
              }
              scheduledIncident={modal.scheduledIncident}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Incidents;

interface AccordionIncidentsProps {
  incidents: Incident[];
  isAdmin: boolean;
  setModal: (value: ModalCreateIncidentObject) => void;
}

const OngoingIncidents = (props: AccordionIncidentsProps) => {
  const { incidents, isAdmin, setModal } = props;

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "22px" }}
      >
        <Typography variant="h1">Incidents en cours</Typography>
        {isAdmin && (
          <Button
            variant="outlined"
            onClick={() => setModal({ open: true, scheduledIncident: false })}
            startIcon={<CobaltIcon name="plus" />}
          >
            Ajouter
          </Button>
        )}
      </Stack>
      <Stack spacing={2}>
        {incidents.map(
          (incident, key) =>
            !incident.date &&
            incident.isActive && (
              <CardIncident key={key} values={incident} isAdmin={isAdmin} />
            ),
        )}
      </Stack>
    </Stack>
  );
};

const ScheduledIncidents = (props: AccordionIncidentsProps) => {
  const { incidents, isAdmin, setModal } = props;

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "22px" }}
      >
        <Typography variant="h1" color="cobalt.ultramarine">
          Incidents programmés
        </Typography>

        {isAdmin && (
          <Button
            variant="outlined"
            onClick={() => setModal({ open: true, scheduledIncident: true })}
            startIcon={<CobaltIcon name="plus" />}
          >
            Ajouter
          </Button>
        )}
      </Stack>
      <Stack spacing={2}>
        {incidents.map(
          (incident, key) =>
            incident.date &&
            incident.isActive && (
              <CardIncident key={key} values={incident} isAdmin={isAdmin} />
            ),
        )}
      </Stack>
    </Stack>
  );
};
