import { CobaltIcon, colors } from "@docaposte-agility/da-design-system";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { ProjectFormData, ProjectMember } from "@/types/projects.types";
import { projectMemberValidationSchema } from "@/validations/projects/member.schema";
import * as Yup from "yup";

interface ProjectMembersFormStepProps {
  formik: FormikProps<ProjectFormData>;
}

const ProjectMembersFormStep = (props: ProjectMembersFormStepProps) => {
  const { formik } = props;

  const handleClickAddButton = () => {
    try {
      projectMemberValidationSchema.validateSync(formik.values.memberForm, {
        abortEarly: false,
        strict: true,
      });
      addMember();
    } catch (e) {
      const err = e as Yup.ValidationError;
      err.inner.forEach((fieldErr) =>
        formik.setFieldError(`memberForm.${fieldErr.path}`, fieldErr.message),
      );
    }
  };

  const addMember = () => {
    const member = formik.values.memberForm as ProjectMember;

    formik.resetForm({
      values: {
        ...formik.values,
        members: [...formik.values.members, member],
        memberForm: { firstName: "", lastName: "", isLead: false },
      },
      touched: {
        memberForm: {
          firstName: false,
          isLead: false,
          lastName: false,
        },
      },
      errors: {},
    });
  };

  const removeMember = (index: number) => {
    formik.values.members.splice(index, 1);
    formik.setFieldValue("members", formik.values.members);
  };

  const columns = [
    {
      field: "lastName",
      headerName: "Nom",
      flex: 0.3,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 0.3,
    },
    {
      field: "isLead",
      headerName: "Responsable",
      flex: 0.2,
      type: "boolean",
    },
    {
      field: "a",
      type: "actions",
      headerName: "Actions",
      flex: 0.2,
      getActions: (params: GridRowParams<ProjectMember>) => [
        <GridActionsCellItem
          icon={<CobaltIcon name="trash-2" />}
          onClick={() => removeMember(params.id as number)}
          label="retirer"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h5">Acteurs du projet</Typography>
      <DataGrid
        sx={{ minHeight: 208, maxHeight: 208 }}
        rowHeight={26}
        columns={columns}
        rows={formik.values.members.map((m, i) => ({ ...m, id: i }))}
        hideFooter={true}
      />
      <Box flexGrow={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Textfield
              formik={formik}
              name="memberForm.lastName"
              label="Nom"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Textfield
              formik={formik}
              name="memberForm.firstName"
              label="Prénom"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Checkbox
                  name="memberForm.isLead"
                  checked={formik.values.memberForm?.isLead}
                  onChange={formik.handleChange}
                />
              }
              label="Responsable"
            />
          </Grid>
          <Grid item xs={1} textAlign="right">
            <IconButton onClick={handleClickAddButton}>
              <CobaltIcon
                color={colors.bleuDigital100}
                fontSize={24}
                name="plus-circle"
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
export default ProjectMembersFormStep;
