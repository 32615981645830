import { Tool, ToolList } from "@/types/tools.types";

export const mapToolArrayToToolList = (tools: Array<Tool>): ToolList => {
  return tools.reduce((acc, tool) => {
    const { section } = tool;
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(tool);
    return acc;
  }, {} as ToolList);
};
