import { Dialog } from "@docaposte-agility/da-design-system";
import {
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Incident } from "@/types/incidents.types";

interface ModalClosedIncidentProps {
  incident: Incident;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ModalClosedIncident = (props: ModalClosedIncidentProps) => {
  const { incident, open, setOpen } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="md"
    >
      <DialogTitle sx={{ minWidth: "60vw" }}>
        <div>
          <Typography variant="h4">
            Plus d'informations sur l'incident
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: "flex", alignItems: "center", mt: "0.5vh" }}>
          <Typography
            variant="subtitle1"
            color="cobalt.userBlue"
            sx={{ mr: "0.5vw" }}
          >
            Auteur :
          </Typography>
          <Typography variant="body1" color="cobalt.lynch">
            {incident.author}
          </Typography>
        </Box>
        {incident.date && (
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.5vh" }}>
            <Typography
              variant="subtitle1"
              color="cobalt.userBlue"
              sx={{ mr: "0.5vw" }}
            >
              Date :
            </Typography>
            <Typography variant="body1" color="cobalt.lynch">
              {incident.date.toString()}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", mt: "0.5vh" }}>
          <Typography
            variant="subtitle1"
            color="cobalt.userBlue"
            sx={{ mr: "0.5vw" }}
          >
            Environnements impactés :
          </Typography>
          <Typography variant="body1" color="cobalt.lynch">
            {incident.impactedEnv}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: "0.5vh" }}>
          <Typography
            variant="subtitle1"
            color="cobalt.userBlue"
            sx={{ mr: "0.5vw" }}
          >
            Temps de résolution estimé :
          </Typography>
          <Typography variant="body1" color="cobalt.lynch">
            {incident.resolutionTime}
          </Typography>
        </Box>

        {incident.link && (
          <Box sx={{ display: "flex", alignItems: "center", mt: "0.5vh" }}>
            <Typography
              variant="subtitle1"
              color="cobalt.userBlue"
              sx={{ mr: "0.5vw" }}
            >
              Lien :
            </Typography>
            <Button
              variant="text"
              size="small"
              href={incident.link}
              sx={{ color: "cobalt.lynch" }}
            >
              {incident.link}
            </Button>
          </Box>
        )}
        {incident.description && (
          <Box sx={{ mt: "0.5vh" }}>
            <Typography
              variant="subtitle1"
              color="cobalt.userBlue"
              sx={{ mr: "0.5vw" }}
            >
              Description :
            </Typography>
            <Typography variant="body1" color="cobalt.lynch">
              {incident.description}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalClosedIncident;
