import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: window.__ENV__.REACT_APP_KEYCLOAK_URL,
  realm: window.__ENV__.REACT_APP_KEYCLOAK_REALM ?? "",
  clientId: window.__ENV__.REACT_APP_KEYCLOAK_CLIENTID ?? "",
});

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "login-required",
    })
    .then(() => {
      onAuthenticatedCallback();
    });
};

const isLoggedIn = () => !!keycloak.token;
const getToken = () => keycloak.token;
const doLogin = keycloak.login;
const doLogout = keycloak.logout;
const hasRole = (role) => keycloak.hasResourceRole(role);

keycloak.onTokenExpired = () => {
  keycloak.updateToken(5);
};

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  hasRole,
  getToken,
  isLoggedIn,
};
export default AuthService;
