import * as Yup from "yup";

export const materialRequestValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom est requis"),
  startDate: Yup.date().required("La date de début est requise"),
  endDate: Yup.date().nullable(),
  materials: Yup.array()
    .transform((value, _) => {
      value.pop();
      return value;
    })
    .min(1, "Vous devez ajouter au moins un matériel")
    .of(
      Yup.object().shape({
        name: Yup.string().required("Le nom du matériel est requis"),
        description: Yup.string().required(
          "La description du matériel est requise",
        ),
      }),
    ),
});
