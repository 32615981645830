import {
  Project,
  ProjectFormData,
  ProjectMember,
  UpdateProject,
} from "@/types/projects.types";

export const serializeMember = (member: ProjectMember): string => {
  return `${member.firstName}:${member.lastName}`;
};

export const serializeMembers = (
  members: Array<ProjectMember>,
): { leadsNames: string; devsNames: string } => {
  const leadsNames = members
    .filter((m) => m.isLead)
    .map(serializeMember)
    .join(";");
  const devsNames = members
    .filter((m) => !m.isLead)
    .map(serializeMember)
    .join(";");

  return { leadsNames, devsNames };
};

export const deserializeMember = (
  member: string,
  isLead: boolean,
): ProjectMember => {
  const [firstName, lastName] = member.split(":");
  return { firstName, lastName, isLead };
};

export const deserializeMembers = (
  leadsNames: string,
  devsNames: string,
): Array<ProjectMember> => {
  const splicedLead = leadsNames !== "" ? leadsNames.split(";") : [];
  const splicedDev = devsNames !== "" ? devsNames.split(";") : [];

  const members = new Array<ProjectMember>();

  members.push(...splicedLead.map((m) => deserializeMember(m, true)));
  members.push(...splicedDev.map((m) => deserializeMember(m, false)));

  return members;
};

export const getModifiedFields = (
  base: Project,
  formData: ProjectFormData,
): UpdateProject => {
  const project: UpdateProject = {};
  const { leadsNames: baseLeadsNames, devsNames: baseDevsNames } =
    serializeMembers(base.members);
  const { leadsNames, devsNames } = serializeMembers(formData.members);

  if (base.name !== formData.name) project.name = formData.name;
  if (base.description !== formData.description)
    project.description = formData.description;
  if (baseLeadsNames !== leadsNames) project.leadsNames = leadsNames;
  if (baseDevsNames !== devsNames) project.devsNames = devsNames;
  if (formData.isImageModified) project.image = formData.image;

  return project;
};
