import { Calendar, CobaltIcon } from "@docaposte-agility/da-design-system";
import { IconButton, Popover, TextFieldProps } from "@mui/material";
import dayjs from "dayjs";
import { getIn } from "formik";
import { useEffect, useState } from "react";
import Textfield from "./Textfield";

interface DateInputProps {
  formik: any;
  name: string;
  dateFormat?: string;
  fieldProps?: TextFieldProps;
}

const DateInput = ({
  formik,
  name,
  dateFormat,
  fieldProps,
}: DateInputProps) => {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [calendarInput, setCalendarInput] = useState("");

  const format = dateFormat ?? "DD/MM/YYYY";

  const handleClickCalendarButton = (e: any) => {
    setCalendarAnchorEl(e.target.parentElement.parentElement);
    setCalendarOpen(!isCalendarOpen);
  };

  const handleOnDateChange = (date: Date) => {
    formik.setFieldValue(name, date);

    setCalendarInput(dayjs(date).format(format));
  };

  useEffect(() => {
    const value = getIn(formik.values, name);

    if (value) {
      setCalendarInput(dayjs(value).format(format));
    }
  }, [format, formik.values, name]);

  const handleTextFieldChange = (e: any) => {
    setCalendarInput(e.target.value);

    const date = dayjs(e.target.value, format);

    if (date.isValid()) {
      formik.setFieldValue(name, date.toDate());
    }
  };

  return (
    <>
      <Textfield
        formik={formik}
        fullWidth
        name={name}
        value={calendarInput}
        onClick={handleClickCalendarButton}
        onChange={handleTextFieldChange}
        placeholder={format}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleClickCalendarButton}>
              <CobaltIcon
                name="calendar"
                sx={{
                  cursor: "pointer",
                  color: isCalendarOpen ? "cobalt.ultramarine" : "inherit",
                }}
              />
            </IconButton>
          ),
        }}
        {...fieldProps}
      />
      <Popover
        open={isCalendarOpen}
        onClose={() => setCalendarOpen(false)}
        anchorEl={calendarAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={3}
        sx={{ mt: 1 }}
      >
        <Calendar
          lang="fr"
          date={getIn(formik.values, name) ?? new Date()}
          setDate={handleOnDateChange}
        />
      </Popover>
    </>
  );
};

export default DateInput;
