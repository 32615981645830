import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  createFilterOptions,
} from "@mui/material";
import DateInput from "@/components/DateInput";
import DateRangeInput from "@/components/DateRangeInput";
import LabelWithTooltip from "@/components/LabelWithTooltip";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { useState } from "react";
import {
  MaterialRequestTicket,
  TicketDef,
  TicketFormData,
} from "@/types/tickets.types";
import { materialRequestValidationSchema } from "@/validations/tickets/materialRequest.schema";

const filter = createFilterOptions<string>();

interface MaterialRequestTicketFromProps {
  formik: FormikProps<TicketFormData<MaterialRequestTicket>>;
  onlyMaterials?: boolean;
}

const getMaterialRequestDefaultValues = (): MaterialRequestTicket => ({
  firstName: "",
  lastName: "",
  startDate: new Date(),
  endDate: null,
  materials: [{ name: "", description: "" }],
});

const serializeMaterialRequestData = (
  data: TicketFormData<MaterialRequestTicket>,
) => ({
  title: data.title,
  content: {
    ...data.content,
    startDate: data.content.startDate.toISOString(),
    endDate: data.content.endDate?.toISOString() ?? null,
    materials: data.content.materials.slice(
      0,
      data.content.materials.length - 1,
    ),
  },
});

const materialTypesOptions = [
  "Souris",
  "Tapis de souris",
  "Clavier",
  "Écran",
  "Pc fixe",
  "Pc portable",
  "Casque",
  "Micro",
  "Webcam",
  "Câble hdmi",
  "Câble vga",
  "Câble ethernet",
  "Câble d'alimentation",
  "Multiprise",
  "Adaptateur",
];

export const materialRequestTicketDef: TicketDef<MaterialRequestTicket> = {
  label: "Demande de matériel",
  validationSchema: materialRequestValidationSchema,
  getComponent: (
    formik: FormikProps<TicketFormData<MaterialRequestTicket>>,
  ) => <MaterialRequestTicketForm formik={formik} key="nc" />,
  defaultValues: getMaterialRequestDefaultValues,
  serializeData: serializeMaterialRequestData,
};

const MaterialRequestTicketForm = ({
  formik,
  onlyMaterials = false,
}: MaterialRequestTicketFromProps) => {
  const [includesEndDate, setIncludesEndDate] = useState(false);
  const materials = formik.values.content.materials;

  const handleIncludeEndDateChange = (e) => {
    setIncludesEndDate(e.target.checked);
    if (!e.target.checked) {
      formik.setFieldValue("content.endDate", null);
    }
  };

  const handleAddMaterial = () => {
    formik.setFieldValue("content.materials", [
      ...formik.values.content.materials,
      { name: null, description: "" },
    ]);
  };

  const handleRemoveMaterial = (index: number) => {
    formik.setFieldValue(
      "content.materials",
      formik.values.content.materials.filter((_, i) => i !== index),
    );
  };

  const handleNameChange = (index: number, value: string) => {
    formik.setFieldValue(`content.materials[${index}].name`, value);
  };
  return (
    <Grid container spacing={2}>
      {!onlyMaterials && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3" color="cobalt.userBlue">
              Utilisateur
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Textfield label="Nom" formik={formik} name="content.lastName" />
          </Grid>
          <Grid item xs={6}>
            <Textfield
              label="Prénom"
              formik={formik}
              name="content.firstName"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="cobalt.userBlue">
              Dates
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Checkbox
                  checked={includesEndDate}
                  onChange={handleIncludeEndDateChange}
                />
              }
              label="Ajouter une date de retour"
            />
          </Grid>

          <Grid item xs={12}>
            {includesEndDate ? (
              <DateRangeInput
                startName="content.startDate"
                endName="content.endDate"
                formik={formik}
                startProps={{ label: "Date de début" }}
                endProps={{ label: "Date de retour" }}
              />
            ) : (
              <DateInput
                formik={formik}
                name="content.startDate"
                fieldProps={{ label: "Date de début" }}
              />
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Matériel
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {materials.length > 1 && (
          <Grid container spacing={2}>
            {materials.slice(0, materials.length - 1).map((material, index) => (
              <>
                <Grid item xs={5} key={`mat-name.${index}`}>
                  <Autocomplete
                    disablePortal
                    options={materialTypesOptions}
                    value={materials[index].name || null}
                    onChange={(_, value) =>
                      handleNameChange(index, value ?? "")
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        p: 0,
                        "& .MuiAutocomplete-input": { p: "12px 16px" },
                      },
                    }}
                    renderInput={(params) => (
                      <Textfield
                        {...params}
                        formik={formik}
                        name={`content.materials[${index}].name`}
                        label={
                          <LabelWithTooltip
                            label="Choix du matériel"
                            tooltipTitle="Le choix n'est pas restrictif, vous pouvez choisir un matériel qui n'est pas dans la liste."
                          />
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} key={`mat-desc.${index}`}>
                  <Textfield
                    formik={formik}
                    name={`content.materials[${index}].description`}
                    fullWidth
                    label="Description"
                    multiline
                    minRows={1}
                    maxRows={4}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        p: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  display="flex"
                  alignItems="end"
                  justifyContent="center"
                  key={`mat-dele.${index}`}
                >
                  <IconButton onClick={() => handleRemoveMaterial(index)}>
                    <CobaltIcon
                      color="cobalt.redIcon"
                      fontSize={24}
                      name="trash-2"
                    />
                  </IconButton>
                </Grid>
              </>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid item xs={5} key="mat-name.new">
        <Autocomplete
          disablePortal
          freeSolo
          options={materialTypesOptions}
          value={materials[materials.length - 1].name || null}
          onChange={(_, value) =>
            handleNameChange(materials.length - 1, value ?? "")
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option);

            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }
            return filtered;
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
              "& .MuiAutocomplete-input": { p: "12px 16px" },
            },
          }}
          renderInput={(params) => (
            <Textfield
              {...params}
              name={`content.materials[${materials.length - 1}].name`}
              formik={formik}
              error={
                typeof formik.errors.content?.materials === "string" &&
                Boolean(formik.errors.content?.materials)
              }
              helperText={
                typeof formik.errors.content?.materials === "string" &&
                formik.errors.content?.materials
              }
              label={
                <LabelWithTooltip
                  label="Choix du matériel"
                  tooltipTitle="Le choix n'est pas restrictif, vous pouvez choisir un matériel qui n'est pas dans la liste."
                />
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={6} key="mat-desc.new">
        <Textfield
          fullWidth
          formik={formik}
          label="Description"
          name={`content.materials[${materials.length - 1}].description`}
          multiline
          error={
            typeof formik.errors.content?.materials === "string" &&
            Boolean(formik.errors.content?.materials)
          }
          helperText={
            typeof formik.errors.content?.materials === "string" &&
            formik.errors.content?.materials
          }
          minRows={1}
          maxRows={4}
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={1} display="flex" alignItems="end" justifyContent="center">
        <IconButton onClick={handleAddMaterial}>
          <CobaltIcon
            color="cobalt.bleuDigital100"
            fontSize={24}
            name="plus-circle"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MaterialRequestTicketForm;
