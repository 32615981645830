import { Level } from "@/types/incidents.types";

const english2french = new Map<Level, string>([
  ["Critical", "Critique"],
  ["High", "Haute"],
  ["Medium", "Moyenne"],
  ["Low", "Basse"],
  ["Unknown", "Inconnue"],
]);

export const mapLevelToFrench = (level: Level): string =>
  english2french.get(level) || level;

export const levelToColor = (level: Level): string => {
  switch (level) {
    case "Critical":
      return "#D82A2A";
    case "High":
      return "#ED6508";
    case "Medium":
      return "#EAB60A";
    case "Low":
      return "#6181FF";
    case "Unknown":
      return "#C2C5D3";
  }
};
