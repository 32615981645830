import { useIncidents } from "@/services/IncidentsService";
import { Badge, badgeClasses } from "@mui/material";
import { useMemo } from "react";

const IncidentRouteLabelWithBadge = () => {
  const { incidents } = useIncidents();

  const count = useMemo(
    () => incidents.filter((incident) => incident.isActive).length,
    [incidents],
  );

  return (
    <Badge
      badgeContent={count}
      color="error"
      sx={{
        [`& .${badgeClasses.badge}`]: {
          right: -5,
          top: 4,
        },
      }}
    >
      Suivi des Incidents
    </Badge>
  );
};

export default IncidentRouteLabelWithBadge;
