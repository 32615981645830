import { CobaltIcon, Dialog } from "@docaposte-agility/da-design-system";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  mapFormDataToProjectFromApi,
  mapProjectToFormData,
} from "@/mappers/projects.mappers";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useCreateProject, useUpdateProject } from "@/services/ProjectsService";
import { Project, ProjectFormData } from "@/types/projects.types";
import { getModifiedFields } from "@/utils/projects.utils";
import { projectDetailsValidationSchema } from "@/validations/projects/details.schema";
import { projectMembersValidationSchema } from "@/validations/projects/member.schema";
import { projectVmsValidationSchema } from "@/validations/projects/vm.schema";
import ProjectDetailsFormStep from "./ProjectDetailsFormStep";
import ProjectMembersFormStep from "./ProjectMembersFormStep";

interface ModalEditProjectBaseProps {
  open: boolean;
  onClose: () => void;
}

type ModalEditProjectProps = ModalEditProjectBaseProps &
  ({ mode: "create" } | { mode: "edit"; project: Project });

const defaultData = {
  id: "",
  imageName: "",
  members: [],
  vms: [],
  name: "",
  description: "",
  isImageModified: false,
  memberForm: {
    firstName: "",
    lastName: "",
    isLead: false,
  },
};

const validationSchemas = [
  projectDetailsValidationSchema,
  projectMembersValidationSchema,
  projectVmsValidationSchema,
];

const ModalEditProject = (props: ModalEditProjectProps) => {
  const { open, onClose, mode } = props;

  const queryClient = useQueryClient();

  const {
    mutate: createProject,
    isLoading: isCreatingProject,
    isSuccess: isCreateSuccessful,
  } = useCreateProject({
    onSuccess() {
      if (mode !== "create") return;
      queryClient.invalidateQueries("projects");
    },
  });

  const {
    mutate: updateProject,
    isLoading: isUpdatingProject,
    isSuccess: isUpdateSuccessful,
  } = useUpdateProject({
    onSuccess() {
      if (mode !== "edit") return;
      queryClient.invalidateQueries(`project.${props.project.id}`);
    },
  });

  const isLoading = isCreatingProject || isUpdatingProject;
  const isSuccess = isCreateSuccessful || isUpdateSuccessful;

  const initialValues = (
    mode === "edit" ? mapProjectToFormData(props.project) : defaultData
  ) as ProjectFormData;

  const [stepNumber, setStepNumber] = useState(0);

  const getButtonAction = () => {
    if (stepNumber === formSteps.length) return handleClose;
    if (stepNumber === formSteps.length - 1) return handleSubmit;
    return nextStep;
  };

  const formik = useFormik<ProjectFormData>({
    initialValues,
    onSubmit: () => getButtonAction()(),
    validationSchema: validationSchemas[stepNumber],
    validateOnBlur: false,
    validateOnChange: false,
  });

  const formSteps = [
    {
      component: <ProjectDetailsFormStep formik={formik} />,
      label: "Détails",
    },
    {
      component: <ProjectMembersFormStep formik={formik} />,
      label: "Acteurs",
    },
    // {
    //   component: <ProjectVMsFormStep formik={formik} />,
    //   label: "VMs",
    // },
  ];

  const formStep = formSteps[stepNumber];

  const getButtonLabel = () => {
    if (stepNumber === formSteps.length) return "Terminer";
    if (stepNumber === formSteps.length - 1) return "Valider";
    return "Suivant";
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const nextStep = () =>
    setStepNumber(Math.min(formSteps.length, stepNumber + 1));

  const previousStep = () => setStepNumber(Math.max(0, stepNumber - 1));

  const handleSubmit = () => {
    nextStep();

    if (stepNumber < formSteps.length - 1) return;

    if (mode === "create") {
      createProject({ values: mapFormDataToProjectFromApi(formik.values) });
    } else {
      updateProject({
        id: props.project.id!,
        values: getModifiedFields(props.project, formik.values),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item display="flex" alignItems="center">
            <CobaltIcon name={mode === "edit" ? "edit-3" : "plus"} />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {mode === "create"
                ? "Créer un nouveau projet"
                : `Modifier le projet ${props.project.name}`}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          {stepNumber < formSteps.length ? (
            formStep.component
          ) : (
            <Stack spacing={2} alignItems="center">
              {isLoading ? (
                <>
                  <Typography>
                    {mode === "create" ? "Création" : "Mise à jour"} du
                    project...
                  </Typography>
                  <CircularProgress />
                </>
              ) : isSuccess ? (
                <>
                  <CobaltIcon
                    fontSize={64}
                    name="check-circle"
                    color="cobalt.greenIcon"
                  />
                  <Typography>
                    Le projet a bien été{" "}
                    {mode === "create" ? "créé" : "mis à jour"}
                  </Typography>
                </>
              ) : (
                <>
                  <CobaltIcon
                    fontSize={64}
                    name="alert-circle"
                    color="cobalt.redIcon"
                  />
                  <Typography>
                    Une erreur est survenue lors de la{" "}
                    {mode === "create" ? "création" : "mise à jour"} du projet.
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                onClick={previousStep}
                disabled={stepNumber === 0 || stepNumber === formSteps.length}
              >
                Précédent
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Stepper activeStep={stepNumber} alternativeLabel>
                {formSteps.map((step, i) => (
                  <Step key={i}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={3}>
              <Button sx={{ width: "100%" }} type="submit" disabled={isLoading}>
                {getButtonLabel()}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditProject;
