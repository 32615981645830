import { Typography, Box } from "@mui/material";
import { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useIncidentsStats } from "@/services/IncidentsService";
import { levelToColor, mapLevelToFrench } from "@/mappers/incidents.mappers";
import { Level } from "@/types/incidents.types";

const PieIncidentsLevel = () => {
  const { stats } = useIncidentsStats(30);

  const dataPie = useMemo(() => {
    const count = stats?.count;
    const labels = new Array<Level>(
      "Critical",
      "High",
      "Medium",
      "Low",
      "Unknown",
    );
    const colors = labels.map((label) => levelToColor(label));
    return {
      labels: labels.map((label) => mapLevelToFrench(label)),
      datasets: [
        {
          data: count
            ? [
                count["critical"],
                count["high"],
                count["medium"],
                count["low"],
                count["unknown"],
              ]
            : [],
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    };
  }, [stats]);

  return (
    <Box sx={{ p: "8px" }}>
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", mb: "8px", color: "cobalt.lynch" }}
      >
        Criticité des incidents déclenchés sur les 30 derniers jours
      </Typography>
      <Pie
        data={dataPie}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default PieIncidentsLevel;
