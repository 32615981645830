import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel as MuiFormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import DateInput from "@/components/DateInput";
import Textfield from "@/components/Textfield";
import { FormikProps } from "formik";
import { useMemo, useState } from "react";
import {
  NewComerTicket,
  TicketDef,
  TicketFormData,
} from "@/types/tickets.types";
import { newComerValidationSchema } from "@/validations/tickets/newComer.schema";
import MaterialRequestTicketForm from "./MaterialRequestTicketForm";
import { getBitwardenPlateformRightsDef } from "./new-comer/platforms/BitwardenRequiredRightsForm";
import { getGitlabPlateformRightsDef } from "./new-comer/platforms/GitlabRequiredRightsForm";
import { getKeycloakPlateformRightsDef } from "./new-comer/platforms/KeycloakRequiredRightsForm";
import { getRedminePlateformRightsDef } from "./new-comer/platforms/RedmineRequiredRightsForm";
import { getTeleportPlateformRightsDef } from "./new-comer/platforms/TeleportRequiredRightsForm";

interface NewComerTicketFormProps {
  formik: FormikProps<TicketFormData<NewComerTicket>>;
}

const FormControlLabel = (props) => (
  <MuiFormControlLabel sx={{ mb: 1, ml: 1 }} {...props} />
);

const getNewComerDefaultValues = (): NewComerTicket => ({
  firstName: "",
  lastName: "",
  email: "",
  arrivalDate: new Date(),
  platforms: [],
  addMaterialRequest: false,
  materials: [{ name: "", description: "" }],
  moreInfo: "",
});

const serializeNewComerData = (data: TicketFormData<NewComerTicket>) => ({
  title: data.title,
  content: {
    ...data.content,
    arrivalDate: data.content.arrivalDate.toISOString(),
  },
});
const PLATFORM_PREFIX = "content";

const defaultPlatforms = {
  bitwarden: getBitwardenPlateformRightsDef(`${PLATFORM_PREFIX}.bitwarden`),
  gitlab: getGitlabPlateformRightsDef(`${PLATFORM_PREFIX}.gitlab`),
  graph: {},
  keycloak: getKeycloakPlateformRightsDef(`${PLATFORM_PREFIX}.keycloak`),
  logs: {},
  nexus: {},
  redmine: getRedminePlateformRightsDef(`${PLATFORM_PREFIX}.redmine`),
  supervision: {},
  teleport: getTeleportPlateformRightsDef(`${PLATFORM_PREFIX}.teleport`),
};

export const newComerTicketDef: TicketDef<NewComerTicket> = {
  label: "Demande d'ouverture de compte pour un nouvel arrivant",
  validationSchema: newComerValidationSchema,
  getComponent: (formik: FormikProps<TicketFormData<NewComerTicket>>) => (
    <NewComerTicketForm formik={formik} />
  ),
  defaultValues: getNewComerDefaultValues,
  serializeData: serializeNewComerData,
};

const NewComerTicketForm = ({ formik }: NewComerTicketFormProps) => {
  const [platforms, setPlatforms] = useState(Object.keys(defaultPlatforms));
  const [newPlatform, setNewPlatform] = useState("");

  const formContent = formik.values.content;

  const platformsWithRightsForm = useMemo(
    () =>
      formik.values.content.platforms.filter(
        (platform) => !!defaultPlatforms[platform]?.getRequiredRightsComponent,
      ),
    [formik],
  );

  const handlePlatformChange = (evt) => {
    const { name } = evt.target;
    if (formContent.platforms.includes(name)) {
      // on retire la plateforme
      formik.setFieldValue(
        "content.platforms",
        formContent.platforms.filter((v) => v !== name),
      ); // on retire la plateforme de la liste des plateformes selectionnées

      if (!Object.keys(defaultPlatforms).includes(name)) {
        // si la plateforme n'est pas une plateforme par défaut, on la retire de la liste des plateformes disponibles
        setPlatforms(platforms.filter((v) => v !== name));
      }

      // on retire les paramètres de la plateforme
      formik.setFieldValue(`content.${name}`, undefined);
    } else {
      // on ajoute la plateforme à la liste des plateformes selectionnées
      formContent.platforms.push(name);
      formik.setFieldValue("content.platforms", formContent.platforms);

      // on ajoute les paramètres par défaut de la plateforme, si elle en a
      if (defaultPlatforms[name]?.getDefaultRightValues) {
        formik.setFieldValue(
          `content.${name}`,
          defaultPlatforms[name].getDefaultRightValues(),
        );
      }
    }
  };

  const handleAddPlatform = () => {
    if (!newPlatform || platforms.includes(newPlatform)) return;

    platforms.push(newPlatform);
    setPlatforms(platforms);
    formContent.platforms.push(newPlatform);
    formik.setFieldValue("content.platforms", formContent.platforms);
    setNewPlatform("");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="cobalt.userBlue">
          Détails
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Textfield formik={formik} name="content.lastName" label="Nom" />
      </Grid>
      <Grid item xs={6}>
        <Textfield formik={formik} name="content.firstName" label="Prénom" />
      </Grid>
      <Grid item xs={6}>
        <Textfield formik={formik} name="content.email" label="E-mail" />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          formik={formik}
          name="content.arrivalDate"
          fieldProps={{ label: "Date d'arrivée" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" mt={2} color="cobalt.userBlue">
          Plateformes
        </Typography>
      </Grid>
      {platforms.map((platform) => (
        <Grid
          item
          xs={4}
          sx={{ textTransform: "capitalize" }}
          key={`rr.${platform}`}
        >
          <FormControlLabel
            label={platform}
            control={
              <Checkbox
                checked={formContent.platforms.includes(platform)}
                onChange={handlePlatformChange}
                name={platform}
              />
            }
          />
        </Grid>
      ))}
      <Box width="100%" />
      <Grid item xs={4}>
        <TextField
          placeholder="Nouvelle plateforme"
          value={newPlatform}
          onChange={(e) => setNewPlatform(e.target.value)}
        />
        <Button variant="outlined" onClick={handleAddPlatform} sx={{ ml: 2 }}>
          Ajouter
        </Button>
      </Grid>

      {platformsWithRightsForm.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h3" mt={2} color="cobalt.userBlue">
            Droits nécessaires
          </Typography>
        </Grid>
      )}
      {platformsWithRightsForm.length > 0 &&
        platformsWithRightsForm.map((platform, i) => (
          <Grid item key={`${i}-${platform}`} xs={12}>
            {defaultPlatforms[platform]?.getRequiredRightsComponent(formik)}
          </Grid>
        ))}

      <Grid item xs={12}>
        <Textfield
          formik={formik}
          name="content.moreInfo"
          label="Plus d'informations"
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              p: 0,
            },
          }}
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" mt={2} color="cobalt.userBlue">
          Demande de matériel
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          checked={formContent.addMaterialRequest}
          control={<Checkbox name={"addMaterialRequest"} />}
          onChange={(e) =>
            formik.setFieldValue("content.addMaterialRequest", e.target.checked)
          }
          label={"Ajouter une demande de matériel"}
        />
      </Grid>

      {formContent.addMaterialRequest && (
        <Grid item xs={12}>
          <MaterialRequestTicketForm
            onlyMaterials={true}
            formik={formik as any}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default NewComerTicketForm;
