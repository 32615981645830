import { Project } from "@/types/projects.types";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomCard from "../CustomCard";

interface CardProjectProps {
  project: Project;
}

const CardProject = (props: CardProjectProps) => {
  const { name, members, image, id } = props.project;

  const leadNames = members
    .filter((m) => m.isLead)
    .map((m) => `${m.firstName} ${m.lastName}`)
    .join(", ");

  const imageUrl =
    image && image.data.length > 0
      ? `data:image/png;base64,${image.data}`
      : "/images/illus-project.png";

  return (
    <Link to={`/projets/${id}`} style={{ textDecoration: "none" }}>
      <CustomCard>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h2">{name}</Typography>
            <Typography
              textOverflow="ellipsis"
              overflow="hidden"
              variant="h4"
              sx={{ color: "cobalt.userBlue" }}
            >
              {leadNames}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              src={imageUrl}
              alt="Illustration projet"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </CustomCard>
    </Link>
  );
};

export default CardProject;
