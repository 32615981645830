import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Textfield from "@/components/Textfield";
import { bugReportTicketDef } from "@/components/tickets/BugReportTicketForm";
import { envOpeningTicketDef } from "@/components/tickets/EnvOpeningTicketForm";
import { materialRequestTicketDef } from "@/components/tickets/MaterialRequestTicketForm";
import { materialReturnTicketDef } from "@/components/tickets/MaterialReturnTicketForm";
import { newComerTicketDef } from "@/components/tickets/NewComerTicketForm";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateTicket } from "@/services/TicketService";
import { TicketFormData } from "@/types/tickets.types";
import { newTicketValidationSchema } from "@/validations/tickets/newTicket.schema";
import * as Yup from "yup";

const TICKET_TYPES = {
  "env-opening": envOpeningTicketDef,
  "new-comer": newComerTicketDef,
  "material-request": materialRequestTicketDef,
  "bug-report": bugReportTicketDef,
  "material-return": materialReturnTicketDef,
  // "open-request": openRequestTicketDef,
};

type TicketType = keyof typeof TICKET_TYPES | null;

const NewTicket = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { isLoading, isSuccess, mutateAsync: createTicket } = useCreateTicket();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isLoading) setIsModalOpen(true);
  }, [isLoading]);

  const [ticketType, setTicketType] = useState<TicketType>(null);
  const validationSchema = ticketType
    ? newTicketValidationSchema.concat(
        Yup.object().shape({
          content: TICKET_TYPES[ticketType].validationSchema,
        }),
      )
    : newTicketValidationSchema;

  const formik = useFormik<TicketFormData<any>>({
    initialValues: {
      title: "",
      content: {},
    },
    validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (val) => {
      if (!ticketType) return;
      const toSend = await TICKET_TYPES[ticketType].serializeData(val);
      const values = { title: toSend.title, ...toSend.content };
      await createTicket({ type: ticketType, values });
      formik.resetForm();
      handleChangeTicketType(null);
    },
  });

  const handleChangeTicketType = (newType: TicketType) => {
    const oldTicketType = ticketType;
    if (newType === oldTicketType) return;

    setTicketType(newType);

    if (newType === null) {
      formik.values.content = {};
      navigate("", { replace: true });
      return;
    }

    navigate(`#${newType}`, { replace: true });

    formik.values.content = TICKET_TYPES[newType]?.defaultValues();
  };

  useEffect(() => {
    const newType = hash.slice(1) as TicketType;
    if (hash && ticketType !== newType) {
      const ticketType = hash.slice(1) as TicketType;
      handleChangeTicketType(ticketType);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <Container
      maxWidth={false}
      sx={{
        margin: "1.5rem",
        height: "fit-content",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "16px" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            color="cobalt.bleuDigital100"
            sx={{ textAlign: "center", fontSize: "32px" }}
          >
            Nouveau ticket
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <Textfield name="title" label="Titre" formik={formik} />
          <Autocomplete
            disablePortal
            options={Object.keys(TICKET_TYPES) as TicketType[]}
            defaultValue={null}
            getOptionLabel={(val) => (val && TICKET_TYPES[val].label) || ""}
            onChange={(_, value) => handleChangeTicketType(value)}
            value={ticketType}
            sx={{
              "& .MuiOutlinedInput-root": {
                p: 0,
                "& .MuiAutocomplete-input": {
                  p: "12px 16px",
                  color: "cobalt.bleuDigital100",
                },
              },
            }}
            renderInput={(params) => (
              <TextField {...params} label="Choix du type de ticket" />
            )}
          />
          {ticketType && (
            <Paper sx={{ p: 4 }}>
              {TICKET_TYPES[ticketType]?.getComponent(formik)}
              <Box textAlign="center" mt={4}>
                <Button type="submit">Envoyer</Button>
              </Box>
            </Paper>
          )}
        </Stack>
      </form>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>
          {isLoading
            ? "Création du ticket en cours..."
            : isSuccess
            ? "Le ticket a bien été créé !"
            : "Une erreur est survenue"}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center" mb={2}>
            {isLoading ? (
              <CircularProgress />
            ) : isSuccess ? (
              <CobaltIcon
                fontSize={64}
                name="check-circle"
                color="cobalt.greenIcon"
              />
            ) : (
              <CobaltIcon
                fontSize={64}
                name="alert-circle"
                color="cobalt.redIcon"
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default NewTicket;
